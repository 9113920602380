<template>
    <modal @close="$emit('close')" :autoshow="true" ref="modal" :is-closeable="isCloseable">
        <template #title>Terminate Employee</template>

        <slot>
            <div>
                <form @submit.prevent="onSubmit()">
                    <p>
                        This will terminate the employee in payroll.
                    </p>

                    <form-errors :errors="formErrors"/>

                    <div class="form-row">
                        <div class="col">
                            <form-input label="Termination Date" v-model="formData.termination_date" :errors="errors.termination_date" type="date" />
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="col">
                            <form-input label="Eligible for Rehire" v-model="formData.eligible_for_rehire" :errors="errors.eligible_for_rehire" type="checkbox" />
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="col mt-2">
                            <form-input label="Termination Reason" v-model="formData.termination_reason" :errors="errors.termination_reason" type="select" :options="terminationReasonOptions"/>
                        </div>
                    </div>

                    <div class="form-row" v-if="client.has_pos_integration_altametrics && client.pos_sidebar_work_locations.includes(employee.work_location)">
                        <div class="col">
                            <form-input label="Terminate in POS" v-model="formData.terminate_in_pos" :errors="errors.terminate_in_pos" type="checkbox" />
                        </div>
                    </div>

                </form>
            </div>
        </slot>

        <template #footer>
            <div class="form-row mt-2">
                <div class="col">
                    <button type="button" class="btn btn-block btn-outline-primary" @click.prevent="close()">Cancel</button>
                </div>
                <div class="col">
                    <button type="submit" class="btn btn-block btn-primary" @click.prevent="onSubmit">Terminate Employee</button>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import api from '@/api'
import ModalMixin from '@/mixins/ModalMixin'
import FormMixin from '@/mixins/Form'
import moment from 'moment'

export default {
    mixins: [ModalMixin, FormMixin],
    props: ['client', 'employee', ],
    data() {
        const date_latest = moment().add(364, 'days')

        const terminationReasonOptions = []
        this.client.termination_reasons.forEach((option) => {
            terminationReasonOptions.push({
                text: option,
                value: option,
            })
        })

        const data = {
            formRules: {
                termination_date: {presence: {allowEmpty: false}, date: {latest: date_latest, tooLate: "can't be more than a year from today."}},
                termination_reason: {presence: {allowEmpty: false}},
            },
            formData: this.makeFormData(),
            terminationReasonOptions: terminationReasonOptions,
        }

        return data
    },
    methods: {
        makeFormData() {
            const formData = {
                termination_date: moment(),
                terminate_in_pos: false,
                eligible_for_rehire: true,
                termination_reason: '',
            }

            return formData
        },

        onSubmit(evt) {
            if (!this.validate()) {
                return
            }

            const url = `/clients/${this.client.id}/employees/${this.employee.id}/actions/terminate`;
            this.$store.dispatch('START_LOADING')
            this.$api.post(url, this.getFormData()).then(() => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showInfo("Sent to Payroll", "Terminating in Payroll.")
                this.$emit('close')
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
    }
}
</script>
