<template>
    <div>
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">
                <div class="container-fluid">
                  
                    <div class="container my-4">
                        <div class="row">
                            <div class="col-12">
                                <h3>Terminations Report</h3>

                                <p>
                                    This reports finds employees that are Active and whose:
                                </p>
                                <ul>
                                    <li> last check date is &gt; <b>Lookback Days</b> ago</li>
                                    <li> last hire date is &gt; <b>Lookback Days</b> days ago</li>
                                </ul>

                                <form @submit.prevent class="mt-4">
                                    <form-errors :errors="formErrors" :errorsHtml="formErrorsHtml" />

                                    <div class="form-row">
                                        <div class="col-3">
                                            <form-input label="Lookback Days" v-model="formData.lookback_days" :errors="errors.lookback_days" type="text" inputmode="numeric" />
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col">
                                            <button class="btn btn-outline-primary" @click="queryData">Run Report</button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FormMixin from '@/mixins/Form'
import api from '@/api'

export default {
    mixins: [FormMixin, ],
    data() {
        return {
            formRules: {
                lookback_days: {presence: {allowEmpty: false},},
            },
            formErrorsHtml: [],
            formData: this.makeFormData(),
            debugOutput: '',
        }
    },
    methods: {
        makeFormData() {
            return {
                lookback_days: 60,
            }
        },
        queryData() {
            if (!this.validate()) {
                return
            }

            this.$api.redirect(`/admin/terminations-report?lookback_days=${this.formData.lookback_days}`)
        },
    },
}
</script>
