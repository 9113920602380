<template>
    <modal @close="$emit('close')" ref="modal">
        <template v-slot:title>Hours Import Options</template>

        <slot>
            <form-errors :errors="formErrors"/>

            <div class="form-row pb-2">
                <div class="col">
                    <form-input label="Hours Import Enabled" v-model="formData.has_hours_importer" :errors="errors.has_hours_importer" type="checkbox" />
                </div>
            </div>

            <div class="form-row pb-2" v-if="formData.has_hours_importer">
                <div class="col">
                    <form-input label="Default company code" v-model="formData.pos_default_company_code" :errors="errors.pos_default_company_code" type="text" size="4" maxlength="4" :nocaps="true">
                        <template #popover-helper>
                            <p>
                                When pushing hours into Payroll, we need to provide a default company code. This is usually irrelevant (since we provide the company for each line in the import), but is still required. It must be a company code that's valid for this client.
                            </p>
                        </template>
                    </form-input>
                </div>
            </div>

            <div class="form-row pb-2" v-if="formData.has_hours_importer">
                <div class="col">
                    <form-input label="Default paygroup" v-model="formData.pos_default_paygroup" :errors="errors.pos_default_paygroup" type="text" :nocaps="true">
                        <template #popover-helper>
                            <p>
                                When pushing hours into Payroll, we need to provide a default paygroup. This is usually irrelevant (since we provide the paygroup for each line in the import), but is still required. It must be a paygroup that's valid for this client and the default company.
                            </p>
                        </template>
                    </form-input>
                </div>
            </div>

            <div class="form-row pb-2" v-if="formData.has_hours_importer">
                <div class="col">
                    <form-input label="Matching Method" v-model="formData.matching_method" :errors="errors.matching_method" type="select" :options="matchingMethodOptions" :nocaps="true">
                        <template #popover-helper>
                            <p>
                                When importing hours into Payroll, we need to use either the Employee ID or the SSN, depending on what we get from the POS.
                            </p>
                        </template>
                    </form-input>
                </div>
            </div>

            <div class="form-row pb-2" v-if="formData.has_hours_importer">
                <div class="col">
                    <form-input label="Check for Name Mismatches" v-model="formData.pos_do_mismatch_check" :errors="errors.pos_do_mismatch_check" type="checkbox">
                        <template #popover-helper>
                            <p>
                                Whether to check for employee name mismatches between the POS and Payroll (that is, whether the employee with the number NNN in fact has the same name in Payroll as in the POS).
                            </p>
                        </template>
                    </form-input>
                </div>
            </div>

            <div class="form-row pb-2" v-if="formData.has_hours_importer">
                <div class="col">
                    <form-input label="Use Rate of Pay from POS" v-model="formData.pos_use_payrate_in_hours_import" :errors="errors.pos_use_payrate_in_hours_import" type="checkbox">
                        <template #popover-helper>
                            <p>
                                Whether to use the rate of pay from the POS when importing the hours.
                            </p>
                        </template>
                    </form-input>
                </div>
            </div>

            <div class="form-row pb-2" v-if="formData.has_hours_importer && ['lc', 'qsr', 'sicom'].includes(integration.pos)">
                <div class="col">
                    <form-input label="Flag Floater OT" v-model="formData.pos_check_floaters" :errors="errors.pos_check_floaters" type="checkbox">
                        <template #popover-helper>
                            <p>
                                Whether to show a warning when floaters across multiple stores may have OT.
                            </p>
                        </template>
                    </form-input>
                </div>
            </div>

            <div class="form-row pb-2" v-if="formData.has_hours_importer && ['lc', 'qsr', 'sicom'].includes(integration.pos)">
                <div class="col">
                    <form-input label="Ignore Salaried Hours" v-model="formData.pos_ignore_salaried_hours" :errors="errors.pos_ignore_salaried_hours" type="checkbox">
                        <template #popover-helper>
                            <p>
                                Ignore hours from employees marked as Salaried in Payroll
                            </p>
                        </template>
                    </form-input>
                </div>
            </div>

            <div class="form-row pb-2" v-if="formData.has_hours_importer && ['qsr'].includes(integration.pos)">
                <div class="col">
                    <form-input label="Misc Hrs Earning Code" v-model="formData.qsr_mischrs_earning_code" :errors="errors.qsr_mischrs_earning_code" type="text" size="5" maxlength="5" :nocaps="true">
                        <template #popover-helper>
                            <p>
                                If Misc Hrs are reported, assign them to this earning code in Payroll.
                            </p>
                        </template>
                    </form-input>
                </div>
            </div>

            <div class="form-row pb-2" v-if="formData.has_hours_importer && ['qsr'].includes(integration.pos)">
                <div class="col">
                    <form-input label="Misc Amt Earning Code" v-model="formData.qsr_miscamt_earning_code" :errors="errors.qsr_miscamt_earning_code" type="text" size="5" maxlength="5" :nocaps="true">
                        <template #popover-helper>
                            <p>
                                If Misc Amt dollars are reported, assign them to this earning code in Payroll.
                            </p>
                        </template>
                    </form-input>
                </div>
            </div>

            <div class="form-row pb-2" v-if="formData.has_hours_importer && ['macromatix'].includes(integration.pos)">
                <div class="col">
                    <form-input label="Override Company ID Prefix" v-model="formData.pos_special_company_emp_id_prefix" :errors="errors.pos_special_company_emp_id_prefix" type="text" size="5" maxlength="5" :nocaps="true">
                        <template #popover-helper>
                            <p>
                                Macromatix only: if this is set, employees whose IDs start with this prefix will have their hours/earnings go into a specific company (see below).
                            </p>
                        </template>
                    </form-input>
                </div>
            </div>

            <div class="form-row pb-2" v-if="formData.has_hours_importer && ['macromatix'].includes(integration.pos)">
                <div class="col">
                    <form-input label="Override Company Code" v-model="formData.pos_special_company_company_code" :errors="errors.pos_special_company_company_code" type="text" size="5" maxlength="5" :nocaps="true">
                        <template #popover-helper>
                            <p>
                                Macromatix only: see above - if it is set, employees whose IDs start with that string will be routed to this company (enter company code).
                            </p>
                        </template>
                    </form-input>
                </div>
            </div>

            <div class="form-row pb-2" v-if="formData.has_hours_importer && ['lc_alt'].includes(integration.pos)">
                <div class="col">
                    <form-input label="Only import posted hours" v-model="formData.pos_only_posted_hours" :errors="errors.pos_only_posted_hours" type="checkbox">
                        <template #popover-helper>
                            <p>
                                Only import hours that have been POSTED.
                            </p>
                        </template>
                    </form-input>
                </div>
            </div>

            <div class="form-row pb-2" v-if="formData.has_hours_importer && ['lc_alt_api'].includes(integration.pos)">
                <div class="col">
                    <form-input label="Allow File Upload option" v-model="formData.pos_allow_upload" :errors="errors.pos_allow_upload" type="checkbox">
                        <template #popover-helper>
                            <p>
                                Allow the user to provide a file instead of the normal process
                            </p>
                        </template>
                    </form-input>
                </div>
            </div>

            <div v-if="formData.has_hours_importer">
                <h5 class="mt-2 mb-2">
                    Org Level Mappings

                    <more-info class="moreinfo-inline mt-0">
                        Field to use for org_levels during POS hours import:
                        <ul>
                            <li> If it's a literal value, use that field from the POS file itself (so 'Store' means use the 'Store' column from the POS)</li>
                            <li> If it starts with '^', look up in Org Levels table</li>
                            <li> If it's the literal '%', look up the org value in the Stores list (based on store)</li>
                            <li> If it's the literal '$', derive the value from the immediately preceding org level parent</li>
                        </ul>
                    </more-info>
                </h5>

                <form-input label="Org Level 1" v-model="formData.org_level_1_pos_hours_import_field" :errors="errors.org_level_1_pos_hours_import_field" :nocaps="true" type="text" />
                <form-input label="Org Level 2" v-model="formData.org_level_2_pos_hours_import_field" :errors="errors.org_level_2_pos_hours_import_field" :nocaps="true" type="text" />
                <form-input label="Org Level 3" v-model="formData.org_level_3_pos_hours_import_field" :errors="errors.org_level_3_pos_hours_import_field" :nocaps="true" type="text" />
                <form-input label="Org Level 4" v-model="formData.org_level_4_pos_hours_import_field" :errors="errors.org_level_4_pos_hours_import_field" :nocaps="true" type="text" />
                <form-input label="Org Level 5" v-model="formData.org_level_5_pos_hours_import_field" :errors="errors.org_level_5_pos_hours_import_field" :nocaps="true" type="text" />
            </div>

        </slot>

        <template v-slot:footer>
            <div class="form-row">
                <div class="col">
                    <button type="button" class="btn btn-block btn-outline-primary" @click.prevent="close()">Cancel</button>
                </div>
                <div class="col">
                    <button type="submit" class="btn btn-block btn-primary" @click.prevent="onSubmit()">Save</button>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import api from '@/api'
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'

export default {
    mixins: [FormMixin, ModalMixin],
    props: ['client', 'integration', ],
    data() {
        const boolOptions = [
            {text: 'Yes', value: true},
            {text: 'No', value: false},
        ]

        const matchingMethodOptions = [
            {text: 'Employee ID', value: 10},
            {text: 'SSN', value: 20},
        ]

        return {
            dataLoaded: false,
            boolOptions: boolOptions,
            matchingMethodOptions: matchingMethodOptions,
            formData: this.makeFormData(),
        }
    },
    mounted() {

    },
    computed: {
        formRules() {
            let rules = {}

            if (this.formData.has_hours_importer) {
                rules['pos_default_company_code'] = {presence: {allowEmpty: false}, }
                rules['pos_default_paygroup'] = {presence: {allowEmpty: false}, }
                rules['matching_method'] = {presence: {allowEmpty: false}, }
            }

            return rules
        },
    },
    methods: {
        makeFormData() {
            let fields = [
                'has_hours_importer', 'pos_default_company_code', 'pos_default_paygroup', 'matching_method',
                'pos_do_mismatch_check', 'pos_use_payrate_in_hours_import', 'pos_check_floaters', 'pos_ignore_salaried_hours',
                'qsr_mischrs_earning_code', 'qsr_miscamt_earning_code',
                'pos_special_company_emp_id_prefix', 'pos_special_company_company_code',
                'pos_only_posted_hours', 'pos_allow_upload',
                'org_level_1_pos_hours_import_field', 'org_level_2_pos_hours_import_field', 'org_level_3_pos_hours_import_field',
                'org_level_4_pos_hours_import_field', 'org_level_5_pos_hours_import_field',
            ]
            let formData = {}

            fields.forEach(f => {
                formData[f] = this.integration[f]
            })
            return formData
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }

            this.$store.dispatch('START_LOADING')
            let url = `/admin/clients/${this.client.id}/pos-integrations/${this.integration.id}/hours-import`
            api.put(url, this.formData).then((resp) => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
                this.close()
            }).catch((errors) => {
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })
        },
    }
}
</script>
