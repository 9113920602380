<template></template>

<script>
import TourMixin from '@/mixins/TourMixin'

export default {
    mixins: [TourMixin],
    props: ['client'],
    components: {},
    data() {
        return {}
    },
    methods: {
        start(section) {
            let steps = []
            if (!section) { // start at first dashboard section by default
                // Managers with SETTINGS_ROLES see expanded tour including the HR Documents Library
                if (this.$store.state.security_roles.SETTINGS_ROLES && this.$permissions.hasRoles(this.client.id, this.$store.state.security_roles.SETTINGS_ROLES)) {
                    steps = [
                        {
                            element: '#navbarMainMenuLink',
                            popover: {
                                title: 'HR Documents Library',
                                description: 'Open this menu and select <strong>Settings</strong> to access your HR Documents Library.',
                            },
                            moveNextOnElementClick: true
                        },
                        {
                            element: '#navbarDropdownMenuSettings',
                            popover: {
                                title: 'HR Documents Library',
                                description: 'Select <strong>Settings</strong> to access your HR Documents Library.',
                            },
                            moveNextOnElementClick: true
                        },
                    ]
                } else { // Managers without SETTINGS_ROLES go directly to the HR Documents page and continue from there
                    steps = [
                        {
                            element: '#navbarMainMenuLink',
                            popover: {
                                title: 'HR Documents',
                                description: 'Open this menu and select <strong>HR Documents</strong> to see the HR Documents that have been sent to your employees.',
                            },
                            moveNextOnElementClick: true
                        },
                        {
                            element: '#navbarDropdownMenuHRDocs',
                            popover: {
                                title: 'HR Documents',
                                description: 'Select <strong>HR Documents</strong> to continue.',
                            },
                            moveNextOnElementClick: true
                        },
                    ]
                }
            } else if (section == 'settings-documents') {
                steps = [
                    {
                        element: '.router-link-exact-active', // TODO KB why isn't this working on back?
                        popover: {
                            title: 'HR Documents Library',
                            description: 'The HR Document Library contains documents that can be sent out to any employee or positions.',
                            disableButtons: [],
                            onPrevClick: () => {
                                this.$router.push({name: 'employer-home', params: {hashedClientId: this.$hasher.encode(this.client.id)}})
                            }
                        }
                    },
                    {
                        element: '#addDocumentButton',
                        popover: {
                            title: 'Add Document Button',
                            description: 'You can add a new document and assign it to positions here. When you’re ready to add your first document, our wizard will guide you through the process.',
                        },
                    },
                    {
                        element: '#section-draft .collapse-trigger',
                        popover: {
                            title: 'Draft Documents',
                            description: 'Documents here have been saved to be sent out later...',
                            onNextClick: () => {
                                document.querySelector('#section-draft .collapse-trigger.is-expanded')?.click()
                                this.$nextTick(() => {
                                    this.tour.moveNext()
                                })
                            },
                        },
                        onHighlightStarted: (element) => {
                            document.querySelector('#section-draft .collapse-trigger:not(.is-expanded)')?.click()
                        }
                    },
                    {
                        element: '#section-active .collapse-trigger',
                        popover: {
                            title: 'Active Documents',
                            description: '...and documents here have already been sent to employees.',
                            onNextClick: () => {
                                document.querySelector('#section-active .collapse-trigger.is-expanded')?.click()
                                this.$nextTick(() => {
                                    this.tour.moveNext()
                                })
                            },
                        },
                        onHighlightStarted: (element) => {
                            document.querySelector('#section-active .collapse-trigger:not(.is-expanded)')?.click()
                        }
                    },
                    {
                        element: '#section-inactive .collapse-trigger',
                        popover: {
                            title: 'Inactive Documents',
                            description: 'Once any document has been filled out by at least one employee, it can no longer be deleted from the library. To stop sending a document to employees, simply deactivate it to move it into the “Inactive Documents” section. Inactive documents can always be re-activated.',
                            onNextClick: () => {
                                this.$emit('select_example_doc')
                                this.$nextTick(() => {
                                    this.tour.moveNext()
                                })
                            },
                        },
                    },
                    {
                        element: '.drawer',
                        popover: {
                            title: 'Document Sidebar',
                            description: 'Detailed information on a library document can be viewed and edited from its sidebar.',
                            onPrevClick: () => {
                                this.$emit('deselect_example_doc')
                                this.tour.movePrevious()
                            },
                        },
                        onHighlightStarted: (element) => {
                            document.querySelector('#section-active .collapse-trigger:not(.is-expanded)')?.click()
                        }
                    },
                    {
                        element: '#hrDocVersionsWidget',
                        popover: {
                            title: 'Versions',
                            description: 'In this section you can view the current version of a document as well as all previous versions. This is also where you will go to create a new version (which you can save as a draft until you are ready to use it).',
                        },
                    },
                    {
                        element: '#requirementWidgets',
                        popover: {
                            title: 'Positions & Individuals Required',
                            description: 'View which positions and individuals are required to complete this document here.'
                        },
                    },
                    {
                        element: '#collectionSettingsWidget',
                        popover: {
                            title: 'Collection Settings',
                            description: 'It’s up to you how you’d like to collect the information. Send it to your employees digitally, or assign a manager to upload copies to employee records, or fill out a form on their behalf. These settings can be set up differently for new employees being onboarded and for existing employees.'
                        },
                    },
                    {
                        element: '#terminationSettingsWidget',
                        popover: {
                            title: 'Termination Settings',
                            description: 'Plan ahead for termination. Ensure that terminated employees are still required to complete critical documents, or remove unnecessary documents from their tasks after termination.',
                            onNextClick: () => {
                                this.$emit('close_sidebar')
                                this.openMainMenu()
                                this.tour.moveNext()
                            },
                        },
                    },
                    {
                        element: '#navbarDropdownMenuHRDocs',
                        popover: {
                            title: 'HR Docs Page',
                            description: 'Let’s take a look at what HR Documents look like after they have been sent to employees. Click <strong>HR Documents</strong> to continue.',
                            onPrevClick: () => {
                                this.closeMainMenu()
                                this.tour.movePrevious()
                            },
                        },
                        moveNextOnElementClick: true,
                    }
                ]
                this.setConfig({
                    smoothScroll: false,
                    disableActiveInteraction: true,
                })
            } else if (section == 'hr-documents-completed') {
                steps = [
                    {
                        element: '#navSecondary-hr-documents',
                        popover: {
                            title: 'Completed Documents',
                            description: 'Completed documents can be reviewed here for both active and inactive employees.',
                            doneBtnText: 'Next',
                            disableButtons: [],
                            onNextClick: () => {
                                this.$router.push({name: 'hr-documents-canceled', params: {hashedClientId: this.$hasher.encode(this.client.id)}})
                            },
                            onPrevClick: () => {
                                // TODO KB this should depend on if we're adding a previous step
                                this.$router.push({name: 'settings-hr-documents', params: {hashedClientId: this.$hasher.encode(this.client.id)}})
                            }
                        },
                        onHighlightStarted: (element) => {
                            this.closeMainMenu()
                        },
                    },
                ]
                // if this is a non-admin user, prepend another step here for more context since they skipped settings-documents
                if (this.$store.state.security_roles.SETTINGS_ROLES && !this.$permissions.hasRoles(this.client.id, this.$store.state.security_roles.SETTINGS_ROLES)) {
                    steps.unshift({
                        popover: {
                            title: 'HR Documents',
                            description: 'HR Documents are displayed here after they have been sent to employees, categorized by completed, incomplete, and canceled documents.',
                            doneBtnText: 'Next',
                            disableButtons: [],
                            onPrevClick: () => {
                                this.$router.push({name: 'settings-hr-documents', params: {hashedClientId: this.$hasher.encode(this.client.id)}})
                            }
                        },
                        onHighlightStarted: (element) => {
                            this.closeMainMenu()
                        },
                    })
                }
            } else if (section == 'hr-documents-canceled') {
                steps = [
                    {
                        element: '#navSecondary-hr-documents-canceled',
                        popover: {
                            title: 'Canceled Documents',
                            description: 'We all make mistakes. You and your managers have the ability to cancel documents that were sent out to the wrong employee, and review any cancellations here.',
                            doneBtnText: 'Next',
                            disableButtons: [],
                            onNextClick: () => {
                                this.$router.push({name: 'hr-documents-incomplete', params: {hashedClientId: this.$hasher.encode(this.client.id)}})
                            },
                            onPrevClick: () => {
                                this.$router.push({name: 'hr-documents', params: {hashedClientId: this.$hasher.encode(this.client.id)}})
                            }
                        }
                    },
                ]
            } else if (section == 'hr-documents-incomplete') {
                steps = [
                    {
                        element: '#navSecondary-hr-documents-incomplete',
                        popover: {
                            title: 'Incomplete Documents',
                            description: `HR Documents awaiting completion are stored here, grouped by status. Managers can easily track employees that have yet to complete an assigned document. Managers can also see documents that are ready for their approval or completion.
                                <br/><br/>Let’s take a look at a specific employee’s document.`,
                            disableButtons: [],
                            onNextClick: () => {
                                this.$emit('select_example_doc')
                                this.$nextTick(() => {
                                    this.tour.moveNext()
                                })
                            },
                            onPrevClick: () => {
                                this.$router.push({name: 'hr-documents-canceled', params: {hashedClientId: this.$hasher.encode(this.client.id)}})
                            }
                        }
                    },
                    {
                        element: '.drawer',
                        popover: {
                            title: 'Employee Sidebar',
                            description: `You can click on John Smith’s example document to see more details about John's documents.`,
                            onNextClick: () => {
                                document.querySelector('#completedDocuments .card-header-inner.collapse-trigger:not(.is-expanded)')?.click()
                                this.tour.moveNext()
                            }
                        },
                    },
                    {
                        element: '#incompleteDocuments',
                        popover: {
                            title: 'Incomplete Documents',
                            description: `An employee’s documents are stored in their Sidebar under the HR Docs tab. Review, approve, or complete any employee’s incomplete documents on their behalf. You can also send a reminder email to the employee to complete the document, or cancel the document from this area.`,
                            onNextClick: () => {
                                this.$emit('scroll_sidebar_top')
                                this.$nextTick(() => {
                                    this.tour.moveNext()
                                })
                            }
                        },
                    },
                    {
                        element: '#completedDocuments',
                        popover: {
                            title: 'Completed Documents',
                            description: `Review or deactivate documents an employee has completed. Documents are never deleted once they are completed, only deactivated.`
                        },
                    },
                    {
                        element: '#addDocButton',
                        popover: {
                            title: 'Add Document to Employee',
                            description: `Assign a document from the Library or an On Demand document to an individual employee here. <br/><br/><strong><em>`,
                            onNextClick: () => {
                                this.$emit('close_sidebar')
                                this.openMainMenu()
                                this.tour.moveNext()
                            }
                        },
                    },
                    {
                        element: '#navbarDropdownMenuHome',
                        popover: {
                            title: 'Dashboard',
                            description: `Now, click <strong>Home</strong> to return to your dashboard to view your at-a-glance compliance data.`
                        },
                        moveNextOnElementClick: true
                    },
                ]
                this.setConfig({
                    smoothScroll: false,
                    disableActiveInteraction: true,
                })
            }  else if (section == 'hr-suite-insights') {
                steps = [
                    {
                        element: '#documentsInsightsWidget',
                        popover: {
                            title: 'Insights',
                            description: 'Track your employee’s compliance from the dashboard in the Insights section, quickly seeing how many documents have been completed and how long it’s been since they were sent out.',
                            disableButtons: [],
                            onPrevClick: () => {
                                this.$router.push({name: 'hr-documents-incomplete', params: {hashedClientId: this.$hasher.encode(this.client.id)}})
                            }
                        },
                        onHighlightStarted: () => {
                            this.closeMainMenu()
                        }
                    },
                    {
                        element: '#documentsInsightsWidgetIncompleteDocs',
                        popover: {
                            title: 'Insights',
                            description: 'Expand the Incomplete Documents data to see a  compliance breakdown by company and document. You can click on any document name to go to the HR Documents page and see which employees have or have not completed that document.',
                        },
                        onHighlightStarted: (element) => {
                            element.querySelector('.collapse-trigger:not(.is-expanded)')?.click()
                        }
                    },
                    {
                        element: '#dashboard-quick-links',
                        popover: {
                            title: 'Help',
                            description: 'Repeat this tutorial at any time by clicking <strong>Help with HR Documents</strong>.',
                            onNextClick: () => {
                                this.$emit('finished')
                                this.tour.moveNext()
                            }
                        },
                    }
                ]
                this.setConfig({
                    smoothScroll: false,
                    disableActiveInteraction: true,
                })
            }
            this.setSteps(steps)
            this._start()
        }
    }
}
</script>