<template>
    <portal :to="modalContainer" v-if="isOpen">
        <div class="modal show" style="display: block" :class="modalBaseClass" tabindex="-1" role="dialog"  ref="modal" v-if="isOpen" @keydown.esc="close">
            <div class="modal-dialog" :class="modalDialogClass" role="document">
                <div class="modal-content" :class="modalHeightClass">
                    <div class="modal-header">
                        <div class="d-flex w-100 flex-wrap">
                            <div class="modal-header-titlebar py-hf">
                                <h3 class="modal-title btnheight-min"><slot name="title"></slot></h3>
                            </div>
                            <button type="button" class="close" aria-label="Close" @click.prevent="close()" v-if="!hideXButton">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div class="modal-header-buttonbar py-hf">
                                <slot name="headerbuttons"></slot>
                            </div>
                        </div>
                        <div class="w-100">
                            <slot name="subheading">
                            </slot>
                        </div>
                    </div>
                    <div class="modal-body d-flex flex-column" style="background-image: none;">
                        <div class="flex-grow-1 modal-scroll-area" :class="modalBodyInnerClass" ref="scrollArea">
                            <slot></slot>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <slot name="footer"></slot>
                    </div>

                    <transition name="slide-up" v-if="hasChildOverlay">
                        <div class="modal-child-overlay" ref="childOverlay" v-if="childOverlayOpen">
                            <div class="modal-child-overlay-inner">
                                <portal-target name="modal-child"></portal-target>
                            </div>
                        </div>
                    </transition>

                </div>
            </div>
        </div>
    </portal>
</template>

<style scoped lang="scss">
  // Import Sass globals
  @import "@/assets/scss/globals";

  //
  // Component styles
  //

  // Auto-show property
  .modal.autoshow {
      display: block;
  }

  // Overlay pane that appears
  // on top of the modal itself,
  // e.g. to display filters when needed

  .modal-child-overlay {
    background-color: rgba(255, 255, 255, 0.65);
    border-radius: $border-radius;
    padding-top: 3.5rem;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9000;
  }

  .modal-child-overlay-inner {
    background-color: $white;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5); // Replace with real box-shadow variable?
    padding: 0;
    height: 100%;

    @include media-breakpoint-up(md) {
        padding-top: spacer(1);
    }

    .modal-content {
        border: none !important;
        box-shadow: none !important;
        height: 100%;
    }
  }

  // Animated transition styles
  // for child overlay pane

  .slide-up-enter-active {
    transition: all .2s ease;
  }

  .slide-up-enter {
    transform: translateY(3.5rem);
    opacity: 0;
  }

  .slide-up-leave-to {
    transform: translateY(-1.5rem);
    opacity: 0;
  }

  .slide-up-leave-active {
    transition: all .15s ease;
  }
</style>

<script>
import $ from 'jquery'

export default {
    props: {
        modalContainer: {
            type: String,
            default: "globalModal",
        },
        modalAdditionalClasses: {
            type: String,
            default: "",
        },
        isCloseable: {
            type: Boolean,
            default: true,
        },
        showFooterPortal: {
            type: Boolean,
            default: false,
        },
        autoshow: {
            type: Boolean,
            default: false,
        },
        fade: {
            type: Boolean,
            default: true,
        },
        size: {
            default: '',
        },
        vCenter: {
            type: Boolean,
            default: false,
        },
        heightMax: {
            type: Boolean,
            default: false,
        },
        hideXButton: {
            type: Boolean,
            default: false,
        },
        bodyInnerClass: {
            default: '',
        },
        options: {
            type: Object,
            default() {
                return {
                    backdrop: false,
                    keyboard: true,
                }
            },
        },
        hasChildOverlay: {
            type: Boolean,
            default: false,
        },
        closeWarningMessage: {
            type: String,
            default: "You have not saved this form. Are you sure you want to close it?",
        }
    },
    data() {
        return {
            isOpen: this.autoshow,
            childOverlayOpen: false,
        }
    },
    watch: {
        isCloseable() {
            this.$store.dispatch('SET_CLOSEABLE', this.isCloseable)
        },
        isOpen(val) {
            if (val) {
                this.$nextTick(() => {
                    this.$refs.modal?.focus()
                })
            }
        }
    },
    mounted() {
        $(window).on('resize', this.resize)
        if (this.isOpen) {
            this.$nextTick(() => {
                this.$refs.modal?.focus()
            })
        }
    },
    computed: {
        modalBaseClass() {
            const classes = []
            if (this.fade === true) {
                classes.push('fade')
            }

            if (this.autoshow) {
                classes.push('show autoshow')
            }

            return classes.join(' ') + this.modalAdditionalClasses
        },
        modalDialogClass() {
            var dialogClasses = ''
            if (this.size) {
                dialogClasses += ' modal-' + this.size
            }
            if (this.vCenter) {
              dialogClasses += ' modal-dialog-centered'
            }
            return dialogClasses
        },
        modalHeightClass() {
            if (this.heightMax) {
                return 'modal-height-max'
            }
            return ''
        },
        modalBodyInnerClass() {
            return this.bodyInnerClass
        },
    },
    methods: {
        open() {
            this.isOpen = true
        },
        close() {
            if (!this.isCloseable) {
                if (!confirm(this.closeWarningMessage)) {
                    return
                }
            }
            this.isOpen = false
            this.$emit('close')
            document.querySelector('.gmps-sidebar .drawer-title-text')?.focus() // send focus back to sidebar if it's open
        },
        scrollBottom() {
            this.$nextTick(() => {
                const $scrollArea = $('.modal-scroll-area')
                $scrollArea.parent().scrollTop($scrollArea.height() * 2)
            })
        },
        resize() {
            this.$nextTick(() => {
                $('.modal').modal('handleUpdate')
            })
        },
        openModalChild() {
            if (this.hasChildOverlay) {
                this.childOverlayOpen = true
            }
            else {
                throw "This modal does not have a child overlay."
            }
        },
        closeModalChild() {
            if (this.hasChildOverlay) {
                this.childOverlayOpen = false
            }
            else {
                throw "This modal does not have a child overlay."
            }
        },
    },
}
</script>
