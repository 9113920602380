<template>
    <aside class="drawer drawer-rowdetail drawer-right collapse no-transition show gmps-sidebar" @keydown.esc="close" aria-hidden="false">

        <div class="drawer-header" :class="{'drawer-header-has-content': (this.$slots.title || this.$slots.subtitle || this.$slots.header)}">
            <div class="drawer-close">
                <button type="button" class="close" aria-label="Close" role="button" aria-expanded="true" @click.prevent="close()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="row">
                <div class="col">
                    <h2 class="drawer-title">
                        <span tabindex="-1" ref="drawerTitleText" class="drawer-title-text">
                            <slot name="title"></slot>
                        </span>
                        <slot name="subtitle"></slot>
                    </h2>
                </div>
            </div>

            <slot name="header"></slot>
        </div>

        <div class="drawer-body flex-container" ref="body">
            <slot></slot>
        </div>

        <div id="sidebar-description">
            <slot name="sidebar-description">
                This sidebar shows details about <slot name="title"></slot>.
                Press the close button or escape key to close the sidebar.
            </slot>
        </div>
    </aside>
</template>

<script>
import $ from 'jquery'

export default {
    methods: {
        close() {
            this.$emit('close')
        },
        scrollTop(offset) {
            this.$nextTick(() => {
                $(this.$refs.body).scrollTop(offset)
            })
        },
    },
    mounted() {
        this.$refs.drawerTitleText.focus()
        this.$bus.$on('close_sidebar', () => {
            this.close()
        })
    },
}
</script>

<style scoped>
    .drawer-title-text:focus {
        outline: none;
    }
</style>

