<template>
    <div>
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">
                <div class="container-fluid">

                    <div class="container-centered-m py-4">

                        <h5>{{ onboardingApplication.company.name }}</h5>
                        <h3 class="mb-4">Direct Deposit Information</h3>

                        <direct-deposit-form
                            :direct-deposit-accounts="onboardingApplication.direct_deposit_accounts"
                            :url="url"
                            :mandatoryDirectDeposit="onboardingApplication.company.has_mandatory_direct_deposit"
                            @updated="onAccountsUpdated($event)"
                        />

                        <alert-box v-if="onboardingApplication.company.has_mandatory_direct_deposit && !(onboardingApplication.direct_deposit_accounts?.length)" type="caution" class="mb-2">
                            Your employer requires direct deposit. If you don't enter a bank account, you will be provided with a paycard by your manager.
                        </alert-box>

                        <form @submit.prevent="onSubmit">
                            <form-errors :errors="formErrors"/>

                            <div class="row mb-2 d-flex align-items-center">
                                <div class="col">
                                    <router-link v-if="previousPage" :to="previousPage">Back</router-link>
                                </div>

                                <div class="col text-right">
                                    <button class="btn btn-primary">Continue</button>
                                </div>
                            </div>

                            <div class="row mb-2 d-flex align-items-center">
                                <div class="col text-center">
                                    <button class="btn btn-link" @click.prevent="skip()">Skip this step</button>
                                </div>
                            </div>
                        </form>

                    </div>

                </div>
            </div>
        </div>
    </div>
</template>


<script>
import FormInput from '@/components/FormInput'
import FormErrors from '@/components/FormErrors'
import FormMixin from '@/mixins/Form'
import WizardMixin from './WizardMixin'

import DirectDepositForm from '@/components/DirectDepositForm'

export default {
    mixins: [FormMixin, WizardMixin],
    components: {
        FormInput,
        FormErrors,
        DirectDepositForm,
    },
    props: ['onboardingApplication'],
    data() {
        return {
            directDepositAccount: null,
            formRules: {
            },
            formData: {
                action: 'setup-direct-deposit',
            },
            url: `/me/onboarding/${this.onboardingApplication.id}/onboarding-application/direct-deposit-accounts`
        }
    },
    methods: {
        skip() {
            const promises = []
            this.onboardingApplication.direct_deposit_accounts.forEach(d => {
                promises.push(this.$api.del(`${this.url}/${d.id}`))
            })
            const allUploadPromises = Promise.all(promises)
            allUploadPromises.then(() => {
                this.onSubmit()
            }).catch(errors => {
                this.$bus.showError('An unknown error has occurred. Please try again.')
            })
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }

            this.$store.dispatch('START_LOADING')
            this.$api.patch(`/me/onboarding/${this.onboardingApplication.id}/onboarding-application`, this.formData).then((resp) => {
                this.$emit('updated', resp)
                this.$emit('next')
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })
        },
        reset() {
            this.errors = {}
            this.formErrors = []
        },
        onAccountsUpdated(app) {
            this.$emit('updated')
        },
    },
}
</script>
