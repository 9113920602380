<template>
    <div class="employer-dashboard">
        <div class="flex-page-content flex-container body-gray">
            <div class="flex-body body-scroll">

                <div class="container mx-auto pl-2 pr-2">

                    <div class="py-4">
                        <div class="d-flex pb-1 align-items-center">
                            <h1 class="mb-0 client-name">{{ client.name }}</h1>
                            <div class="dashboard-filter-btn-container">
                                <button id="dashboard-filter-btn" class="btn ml-2 dashboard-filter-btn" :class="{'btn-primary': filters.length, 'btn-outline-primary': !filters.length}" @click.prevent="$refs.dashboardFilter.openFilterModal()">
                                    <i class="fa-fw fa-filter" :class="{'fas': filters.length, 'far': !filters.length}"></i>
                                    <span v-if="filters.length" class="text-small ml-hf mr-hf">{{ activeFilterCount }}</span>
                                    <span class="d-none d-md-inline-block ml-hf">Filter Page</span>
                                </button>
                            </div>
                        </div>

                        <dashboard-filter
                            v-show="filters.length"
                            :sections="sectionsToFilter"
                            :headers="headersToFilter"
                            :data="dataToFilter"
                            :filteredData="filteredData"
                            @filter="onFilter"
                            :managers="client.company_managers"
                            @filter-edit-open="$emit('filter-edit-open')"
                            @filter-edit-close="$emit('filter-edit-close')"
                            ref="dashboardFilter"
                            class="dashboard-filter"
                            :class="{'dashboard-filter-has-filters': filters.length}"
                        />

                        <heads-up-banner :client="client" headsup="enhanced-ess"/>
                        <heads-up-banner :client="client" headsup="hr-suite"/>

                        <div class="row">
                            <div class="col-12 col-lg-9">
                                <!-- Tasks / Warnings To Do List section -->
                                <div id="dashboard-todo" class="dashboard-section box-shadow-tile">
                                    <div class="d-flex justify-content-between pb-1 pl-1 pr-1">
                                        <div>
                                            <i class="dashboard-section-icon far fa-fw fa-check-circle mr-1 align-top pt-qt text-large"></i><h3 class="d-inline">Your To-Do List</h3>
                                        </div>
                                        <div class="text-muted" v-if="filters.length">
                                            <i class="fas fa-fw fa-filter"></i>
                                            {{ activeFilterCount }}
                                        </div>
                                    </div>
                                    <div class="d-flex flex-direction-col">
                                        <div class="col-12 pl-1 pr-1">
                                            <employer-dashboard-warnings id="dashboard-warnings" v-if="warningsLoaded" :client="client" :warnings="warningsFiltered" @warning-dismissed="getWarnings()"/>
                                        </div>
                                        <div class="col-12 pl-1 pr-1">
                                            <employer-dashboard-tasks id="dashboard-tasks" v-if="tasksLoaded" :client="client" :tasks-by-type="tasksByTypeFiltered"/>
                                        </div>
                                    </div>
                                </div>

                                <!-- Insight Widget Section -->
                                <div class="dashboard-section box-shadow-tile">
                                    <div class="d-flex justify-content-between pl-1 pr-1" id="dashboard-insights">
                                        <div>
                                            <i class="dashboard-section-icon far fa-fw fa-lightbulb-on mr-1 align-top pt-qt text-large"></i><h3 class="d-inline">Insights</h3>
                                        </div>
                                        <div class="pr-1 text-muted" v-if="filters.length">
                                            <i class="fas fa-fw fa-filter"></i>
                                            {{ activeFilterCount }}
                                        </div>
                                    </div>
                                    <div class="d-flex pt-1 flex-direction-col flex-direction-xl-row">
                                        <div v-if="showDocumentsWidget || showESSWidget" class="col-12 col-xl-6 pl-1 pr-1 d-flex flex-direction-col">
                                            <documents-insight-widget
                                                v-if="showDocumentsWidget"
                                                :client="client"
                                                :doc-tasks="documentsWidgetDataFiltered"
                                            />
                                            <ess-insight-widget
                                                v-if="showESSWidget"
                                                :client="client"
                                                :widget-data="essWidgetDataFiltered"
                                                :warnings="essWarningsFiltered"
                                            />
                                        </div>
                                        <div class="col-12 col-xl-6 pl-1 pr-1 d-flex flex-direction-col">
                                            <onboarding-insight-widget
                                                v-if="showOnboardingWidget"
                                                :client="client"
                                                :onboarding-apps="onboardingWidgetDataFiltered"
                                                :warnings="onboardingWarningsFiltered"
                                            />
                                            <!-- <e-verify-insight-widget
                                                v-if="hasEVerify && $permissions.hasRoles(client.id, $store.state.security_roles.EVERIFY_ROLES)"
                                                :client="client"
                                            /> -->
                                        </div>
                                    </div>
                                </div>

                                <!-- <div class="dashboard-section box-shadow-tile">
                                    <div class="pb-1">
                                        <i class="fas fa-fw fa-exclamation mr-1 align-text-top"></i><h3 class="d-inline">New & Improved</h3>
                                    </div>
                                </div> -->
                            </div>
                            <div class="col-12 col-lg-3">
                                <div class="pt-2 pt-lg-0">
                                    <!-- Quick Links section -->
                                    <div id="dashboard-quick-links" class="dashboard-section pb-2 pr-1">
                                        <h4>Quick Links</h4>
                                        <a href class="d-inline-block pt-1 pb-0" @click.prevent="startDashboardTour">
                                            <i class="far mr-hf fa-books fa-question-circle"></i>Help with the Dashboard
                                        </a>
                                        <a v-if="client.has_company_docs_module" href class="d-inline-block pt-1" @click.prevent="startHRSuiteTour">
                                            <i class="far mr-hf fa-books fa-question-circle"></i>Help with HR Documents
                                        </a>
                                    </div>
                                    <!-- Promo Tile -->
                                    <promo-tile class="promo-tile-tall-lg"></promo-tile>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>

        <DashboardTour ref="dashboardTour" @finished="onUserFinishedDashboardTour" @close="onUserFinishedDashboardTour"/>

        <HRSuiteTour ref="hrSuiteTour" @finished="onUserFinishedHRSuiteTour" @close="skipHRSuiteTour" :client="client"/>

        <modal v-if="showHRSuiteTourModal" :autoshow="true" @close="skipHRSuiteTour()" :is-closeable="true">
            <template #title>HR Compliance Suite Tutorial</template>
            <p>Congratulations! Your HR Compliance Suite package has been activated.</p>
            <p>Click <strong>Begin Tour</strong> to walk through the new features that are now available to you.</p>
            <template #footer>
                <button-row stacked>
                    <button type="button" class="btn btn-outline-primary" @click.prevent="skipHRSuiteTour()">Skip Tour</button>
                    <button type="button" class="btn btn-primary" @click.prevent="startHRSuiteTour()">Begin Tour</button>
                </button-row>
                <!-- <div v-if="hrSuiteTourSkipsRemaining > 0" class="d-flex text-center justify-content-start">
                    <div class="col-12 col-rs-6 text-center">
                        <em>{{ hrSuiteTourSkipsRemaining }} skips remaining</em>
                    </div>
                </div> -->
            </template>
        </modal>

    </div>
</template>

<script>
import EmployerDashboardWarnings from '@/components/EmployerDashboard/EmployerDashboardWarnings'
import EmployerDashboardTasks from '@/components/EmployerDashboard/EmployerDashboardTasks'
import DocumentsInsightWidget from '@/components/EmployerDashboard/DocumentsInsightWidget'
import OnboardingInsightWidget from '@/components/EmployerDashboard/OnboardingInsightWidget'
import EssInsightWidget from '@/components/EmployerDashboard/EssInsightWidget'
import PromoTile from '@/components/PromoTile'
import HeadsUpBanner from '@/components/HeadsUpBanner'
import DashboardFilter from '@/components/FastTable/Filter'
import rowMatchesFilters from '@/components/FastTable/filter-utils'
import HRSuiteTour from '@/components/Tours/HRSuiteTour.vue'
import DashboardTour from '@/components/Tours/EmployerDashboardTour.vue'

import moment from 'moment'
import Modal from '@/components/Modal'
import ButtonRow from '@/components/ButtonRow'

export default {
    props: ['client'],
    components: {EmployerDashboardWarnings, EmployerDashboardTasks, PromoTile, HeadsUpBanner, DocumentsInsightWidget, OnboardingInsightWidget, DashboardFilter, EssInsightWidget, Modal, ButtonRow, HRSuiteTour, DashboardTour},
    data() {
        return {
            warnings: [],
            warningsLoaded: false,
            tasksByType: {},
            tasksLoaded: false,
            documentsWidgetExpanded: true,
            documentsWidgetData: [],
            onboardingWidgetData: [],
            essWidgetData: [],
            headersToFilter: [
                {
                    id: "record",
                    label: "Record",
                    isFilterable: false,
                },
                {
                    id: "company",
                    label: "Company",
                    isFilterable: true,
                }
            ],
            sectionsToFilter: [],
            filters: [],
            showHRSuiteTourModal: false,
        }
    },
    watch: {
        dashboardDataLoaded(val) {
            if (val) {
                this.$store.dispatch('STOP_LOADING')
                if (this.$store.state.activeTour == 'hr-suite' && this.$route.query.tourSection == 'hr-suite-insights') {
                    this.startHRSuiteInsightsTour() // when we return to the dashboard for the last part of the HR Suite tour
                } else if (this.shouldStartDashboardTour) {
                    this.startDashboardTour()
                } else if (this.shouldStartHRSuiteTour) {
                    this.showHRSuiteTourModal = true
                }
            }
        },
    },
    computed: {
        hasEVerify() {
            return this.client.companies.filter(c => c.everify_enabled).length > 0
        },
        eVerifyAccess() {
            const userSP = this.$permissions.getSecurityProfile(this.client.id)
            if (userSP) {
                return userSP.everify_access
            }

            return ''
        },
        enhancedESSUpgradeAvailable() {
            if (!(this.$store.state.system_flags && this.$store.state.system_flags['enhanced_ess_released'])) {
                return false
            }
            if (!this.client.has_enhanced_ess) {
                return true
            } else {
                for (let c of this.client.companies) {
                    if (!c.has_enhanced_ess) {
                        return true
                    }
                }
            }
            return false
        },
        warningsTableData() {
            return this.warnings.map(w => {
                return {
                    id: w.id,
                    object: w,
                    cells: [
                        `warning-${w.id}`, w.company.name
                    ],
                }
            })
        },
        warningsTableDataFiltered() {
            if (!this.filters || !this.filters.length) {
                return this.warningsTableData
            }
            return this.warningsTableData.filter(row => rowMatchesFilters(row, this.filters, this.client.company_managers))
        },
        warningsFiltered() {
            if (!this.filters || !this.filters.length) {
                return this.warnings
            }
            return this.warningsTableDataFiltered.map(row => row.object)
        },
        onboardingWarningsFiltered() {
            return this.warningsFiltered.filter(w => w.onboarding_application)
        },
        essWarningsFiltered() {
            return this.warningsFiltered.filter(w => {
                if (w.employee || w.onboarding_application) {
                    if (['bounced-email', 'consent-withdrawn', 'pin-reset'].includes(w.warning_type)) {
                        return true
                    }
                }
                return false
            })
        },
        tasksTableDataByType() {
            let tasksTableDataByType = {}
            for (const [type, tasks] of Object.entries(this.tasksByType)) {
                tasksTableDataByType[type] = tasks.map(t => {
                    return {
                        id: t.id,
                        object: t,
                        cells: [
                            `task-${t.id}`, t.company.name
                        ]
                    }
                })
            }
            return tasksTableDataByType
        },
        tasksTableDataByTypeFiltered() {
            if (!this.filters || !this.filters.length) {
                return this.tasksTableDataByType
            }
            let tasksTableDataByTypeFiltered = {}
            for (const [type, tasks] of Object.entries(this.tasksTableDataByType)) {
                tasksTableDataByTypeFiltered[type] = tasks.filter(row => rowMatchesFilters(row, this.filters, this.client.company_managers))
            }
            return tasksTableDataByTypeFiltered
        },
        tasksByTypeFiltered() {
            if (!this.filters || !this.filters.length) {
                return this.tasksByType
            }
            let tasksByTypeFiltered = {}
            for (const [type, tasks] of Object.entries(this.tasksTableDataByTypeFiltered)) {
                tasksByTypeFiltered[type] = tasks.map(row => row.object)
            }
            return tasksByTypeFiltered
        },
        showDocumentsWidget() {
            return this.$store.state.security_roles?.HR_DOCUMENTS_ROLES && this.$permissions.hasRoles(this.client.id, this.$store.state.security_roles.HR_DOCUMENTS_ROLES)
        },
        showOnboardingWidget() {
            return this.client.has_paperless_onboarding && this.$store.state.security_roles?.ONBOARDING_ROLES && this.$permissions.hasRoles(this.client.id, this.$store.state.security_roles.ONBOARDING_ROLES)
        },
        showESSWidget() {
            return this.client.has_ess && this.$store.state.security_roles?.SELF_SERVICE_ACCESS_ROLES && this.$permissions.hasRoles(this.client.id, this.$store.state.security_roles.SELF_SERVICE_ACCESS_ROLES)
        },
        documentsWidgetTableData() {
            return this.documentsWidgetData.map(d => {
                return {
                    id: d.id,
                    object: d,
                    cells: [
                        `doc-task-${d.id}`, d.company.name
                    ],
                }
            })
        },
        documentsWidgetTableDataFiltered() {
            if (!this.filters || !this.filters.length) {
                return this.documentsWidgetTableData
            }
            return this.documentsWidgetTableData.filter(row => rowMatchesFilters(row, this.filters, this.client.company_managers))
        },
        documentsWidgetDataFiltered() {
            if (!this.filters || !this.filters.length) {
                return this.documentsWidgetData
            }
            return this.documentsWidgetTableDataFiltered.map(d => d.object)
        },
        onboardingWidgetTableData() {
            return this.onboardingWidgetData.map(o => {
                return {
                    id: o.id,
                    object: o,
                    cells: [
                        `onboarding-application-${o.id}`, o.company.name
                    ],
                }
            })
        },
        onboardingWidgetTableDataFiltered() {
            if (!this.filters || !this.filters.length) {
                return this.onboardingWidgetTableData
            }
            return this.onboardingWidgetTableData.filter(row => rowMatchesFilters(row, this.filters, this.client.company_managers))
        },
        onboardingWidgetDataFiltered() {
            if (!this.filters || !this.filters.length) {
                return this.onboardingWidgetData
            }
            return this.onboardingWidgetTableDataFiltered.map(o => o.object)
        },
        essWidgetTableData() {
            return this.essWidgetData.map(u => {
                let subrows = []
                u.employees.forEach(e => {
                    subrows.push(['', e.company.name])
                })
                u.onboarding_applications.forEach(o => {
                    subrows.push(['', o.company.name])
                })
                return {
                    id: u.id,
                    object: u,
                    cells: [
                        `ess-${u.id}`, ''
                    ],
                    subrows: subrows
                }
            })
        },
        essWidgetTableDataFiltered() {
            if (!this.filters || !this.filters.length) {
                return this.essWidgetTableData
            }
            return this.essWidgetTableData.filter(row => rowMatchesFilters(row, this.filters, this.client.company_managers))
        },
        essWidgetDataFiltered() {
            if (!this.filters || !this.filters.length) {
                return this.essWidgetData
            }
            return this.essWidgetTableDataFiltered.map(o => o.object)
        },
        dataToFilter() {
            return [this.warningsTableData, ...Object.values(this.tasksTableDataByType), this.documentsWidgetTableData, this.onboardingWidgetTableData, this.essWidgetTableData]
        },
        filteredData() {
            if (!this.filters || !this.filters.length) {
                return this.dataToFilter
            }
            return [this.warningsTableDataFiltered, ...Object.values(this.tasksTableDataByTypeFiltered), this.documentsWidgetTableDataFiltered, this.onboardingWidgetTableDataFiltered, this.essWidgetTableDataFiltered]
        },
        activeFilterCount() {
            let activeFilterCount = 0
            this.filters.forEach(f => {
                activeFilterCount += f.filterValues.length
            })
            return activeFilterCount
        },
        dashboardDataLoaded() {
            return this.$store.state.user && this.warningsLoaded && this.tasksLoaded // as long as user data, warnings and tasks are loaded, the widgets can trickle in
        },
        dismissalsRecord() {
            let rec = {}
            if (this.$store.state.user) {
                if (this.$store.state.user.dismissals_record['clients']) {
                    if (this.client.id in this.$store.state.user.dismissals_record['clients']) {
                        rec = this.$store.state.user.dismissals_record['clients'][this.client.id]
                    }
                }
            }
            return rec
        },
        skipsRecord() {
            return this.dismissalsRecord['skips'] || {}
        },
        wasHRComplianceVideoWatchedRecently() {
            // did the user watch this video for this client in the last 30 days?
            let lastWatched = this.dismissalsRecord['hr-compliance-video']
            if (lastWatched) {
                let inputformat = 'YYYY-MM-DDTHH:mm:ss.SSSZ'
                let lastWatchedGoodUntil = moment(lastWatched, inputformat).add(30, 'days');
                if (lastWatchedGoodUntil.isSameOrAfter()) {
                    return true
                }
            }
            return false
        },
        wasHRComplianceVideoSkippedThisSession() {
            // did the user skip this video since the last login?
            return this.skipsRecord['hr-compliance-video']?.session_key == this.$store.state.session?.session_key
        },
        wasHRSuiteTourSkippedThisSession() {
            // did the user skip this video since the last login?
            return this.skipsRecord['hr-suite-tour']?.session_key == this.$store.state.session?.session_key
        },
        shouldStartHRComplianceVideo() {
            return !this.$store.state.user.is_superuser && this.$store.state.system_flags?.hr_docs_suite_released && !this.client.has_company_docs_module && !this.wasHRComplianceVideoWatchedRecently && !this.wasHRComplianceVideoSkippedThisSession
        },
        shouldStartDashboardTour() {
            return !this.$store.state.user.is_superuser && this.$store.state.system_flags?.dashboard_released && !this.dismissalsRecord['dashboard-tour']
        },
        shouldStartHRSuiteTour() {
            if (this.$store.state.user.is_superuser) {
                return false
            }
            if (this.$store.state.security_roles?.HR_DOCUMENTS_ROLES && !this.$permissions.hasRoles(this.client.id, this.$store.state.security_roles.HR_DOCUMENTS_ROLES)) {
                return false
            }
            return this.$store.state.system_flags?.hr_docs_suite_released && this.client.has_company_docs_module && !this.dismissalsRecord['hr-suite-tour'] && !this.wasHRSuiteTourSkippedThisSession
        },
        hrSuiteTourSkipsRemaining() {
            let skipsRecord = this.dismissalsRecord['skips']
            if (skipsRecord) {
                let numSkips = skipsRecord['hr-suite-tour']?.num_skips
                if (numSkips) {
                    return 3 - numSkips
                }
            }
            return 3
        },
    },
    mounted() {
        this.$store.dispatch('START_LOADING')
        this.$store.dispatch('SET_PAGE_TITLE', 'Home')
        this.$store.dispatch('GET_USER').then(() => {
            if (this.shouldStartHRComplianceVideo) {
                this.$router.push({name: 'client-features-hr-compliance-video', params: {hashedClientId: this.$route.params.hashedClientId}})
                return
            }
            this.getDocumentsWidgetData()
            this.getOnboardingWidgetData()
            this.getEssWidgetData()
        })

        this.$api.post('/me/last-viewed', {client_id: this.client.id})

        this.getWarnings()
        this.getTasks()
    },
    methods: {
        goToPOS() {
            if (! this.client.has_pos_integration) {
                return
            }

            if (this.client.pos_use_new_ui) {
                this.$router.push({name: 'pos-integration', params: {hashedClientId: this.$hasher.encode(this.client.id)}})
                return
            }

            if (this.client.demo_custom_pos_integration_url) {
                window.location.href = this.client.demo_custom_pos_integration_url
                return
            }

            let pos_app_url = `${this.$delta.POS_APP_URL}/employer/client/${this.client.id}/hours-import/`
            window.location.href = pos_app_url
        },
        onFilter(filters) {
            this.filters = filters
        },
        getDocumentsWidgetData() {
            if (!this.showDocumentsWidget) {
                return
            }
            const url = `/clients/${this.client.id}/dashboard/document-tasks/`
            return this.$api.get(url).then(resp => {
                this.documentsWidgetData = resp
            }).catch(errors => {
                this.$bus.showError(errors.__all__)
            })
        },
        getOnboardingWidgetData() {
            if (!this.showOnboardingWidget) {
                return
            }
            const url = `/clients/${this.client.id}/dashboard/onboarding-applications/`
            this.$api.get(url).then(resp => {
                this.onboardingWidgetData = resp
            }).catch(errors => {
                this.$bus.showError(errors.__all__)
            })
        },
        getEssWidgetData() {
            if (!this.showESSWidget) {
                return
            }
            const url = `/clients/${this.client.id}/dashboard/ess/`
            this.$api.get(url).then(resp => {
                this.essWidgetData = resp
            }).catch(errors => {
                this.$bus.showError(errors.__all__)
            })
        },
        getWarnings() {
            const url = `/clients/${this.client.id}/dashboard/warnings`
            this.$api.get(url).then(resp => {
                this.warnings = resp
                this.warningsLoaded = true
            }).catch(errors => {
                this.warningsLoaded = true
                this.$bus.showError(errors.__all__)
            })
        },
        getTasks() {
            const url = `/clients/${this.client.id}/dashboard/manager-tasks`
            return this.$api.get(url).then(resp => {
                this.tasksByType = resp.tasks_by_type
                this.tasksLoaded = true
            }).catch(errors => {
                this.tasksLoaded = true
                this.$bus.showError(errors.__all__)
            })
        },
        startDashboardTour() {
            this.$refs.dashboardTour.start()
        },
        startHRSuiteTour() {
            this.$store.dispatch('SET_ACTIVE_TOUR', 'hr-suite')
            this.showHRSuiteTourModal = false
            this.$refs.hrSuiteTour.start()
        },
        startHRSuiteInsightsTour() {
            this.$refs.hrSuiteTour.start('hr-suite-insights')
        },
        onUserFinishedDashboardTour() {
            this.$api.post(`/me/dismiss`, {'client_id': this.client.id, 'slug': 'dashboard-tour'}).then(resp => {
                this.$store.dispatch('GET_USER').then(resp => {
                    if (this.shouldStartHRSuiteTour) {
                        this.showHRSuiteTourModal = true // daisy chain hr suite tour after dashboard tour if applicable
                    }
                })
            }).catch(errors => {
                this.$bus.showError(errors.__all__)
            })
        },
        onUserFinishedHRSuiteTour() {
            this.$store.dispatch('SET_ACTIVE_TOUR', null)
            this.$api.post(`/me/dismiss`, {'client_id': this.client.id, 'slug': 'hr-suite-tour'}).then(resp => {
                this.$store.dispatch('GET_USER')
            }).catch(errors => {
                this.$bus.showError(errors.__all__)
            })
        },
        skipHRSuiteTour() {
            this.showHRSuiteTourModal = false
            this.$store.dispatch('SET_ACTIVE_TOUR', null)
            this.$api.post(`/me/dismiss`, {'client_id': this.client.id, 'slug': 'hr-suite-tour', 'action': 'skip', 'session_key': this.$store.state.session.session_key }).then(resp => {
                this.$store.dispatch('GET_USER')
            }).catch(errors => {
                this.$bus.showError(errors.__all__)
            })
        },
    }
}
</script>

<style lang="scss">
.employer-dashboard {
    @media (max-width: 768px) {
        .client-name {
            font-size: 24px;
        }
    }
    @media (max-width: 576px) {
        .client-name {
            font-size: 20px;
        }
    }
    .dashboard-filter-btn-container {
        min-width: 100px;
    }
    .dashboard-filter {
        .btn-toggle--filters {
            display: none;
        }
    }
    @media (min-width: 768px) {
        .dashboard-filter-has-filters {
            min-height: 48px;
            .activefilters-list {
                display: flex;
            }
        }
    }
    .dashboard-section {
        background-color: #F8F8F8;
        border-radius: 4px;
        padding: 12px;
        padding-bottom: 0px;
        margin-bottom: 24px;

        .dashboard-section-icon {
            color: #FE4E00;
        }
    }
    .flex-direction-col {
        flex-direction: column;
    }
    @media (min-width: 1200px) {
        .flex-direction-xl-row {
            flex-direction: row;
        }
    }
}
</style>
