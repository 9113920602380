<template>
    <modal @close="$emit('close')" :autoshow="true" ref="modal" :is-closeable="isCloseable">
        <template #title>POS: Update Job/Rate</template>

        <slot>
            <div>
                <form @submit.prevent="onSubmit()">
                    <p>
                        This will update the employee's job & pay information in <b>Altametrics</b>.
                    </p>

                    <p>
                        It does not impact payroll.
                    </p>

                    <form-errors :errors="formErrors"/>

                    <div class="form-row">
                        <div class="col-8 col-sm-6">
                            <form-input label="Effective Date" v-model="formData.effective_date" :errors="errors.effective_date" type="date" />
                        </div>
                    </div>
                </form>
            </div>
        </slot>

        <template #footer>
            <div class="form-row">
                <div class="col">
                    <button type="button" class="btn btn-block btn-outline-primary" @click.prevent="close()">Cancel</button>
                </div>
                <div class="col">
                    <button type="submit" class="btn btn-block btn-primary" @click.prevent="onSubmit">Save</button>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import api from '@/api'
import ModalMixin from '@/mixins/ModalMixin'
import FormMixin from '@/mixins/Form'
import moment from 'moment'

export default {
    mixins: [ModalMixin, FormMixin],
    props: ['client', 'employee', ],
    data() {
        const date_latest = moment().add(364, 'days')

        const data = {
            formRules: {
                effective_date: {presence: {allowEmpty: false}, date: {latest: date_latest, tooLate: "can't be more than a year from today."}},
            },
            formData: this.makeFormData(),
        }

        return data
    },
    methods: {
        makeFormData() {
            const formData = {
                effective_date: moment()
            }

            return formData
        },

        onSubmit(evt) {
            if (!this.validate()) {
                return
            }

            const url = `/clients/${this.client.id}/employees/${this.employee.id}/pos-actions/update-jobpay`;
            this.$store.dispatch('START_LOADING')
            this.$api.post(url, this.getFormData()).then(() => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showInfo("Sent to POS", "Sent to Altametrics. This may take several minutes.")
                this.$emit('close')
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
    }
}
</script>
