<template>
    <div>
        <div class="flex-page-content flex-container body-gray">
            <div class="flex-body body-scroll">
              
                <div class="container mx-auto">
          
                    <div class="py-4">
                        <div class="row">
                            <div class="col-12">
            
                                <div class="form-row">
            
                                    <div class="col-12 col-ss-6 col-sm-4 col-lg-3">
                                        <router-link class="module-tile" :to="{name: 'admin-clients'}">
                                            <div class="module-tile-inner">
                                                <div class="module-tile-content">
                                                    <span class="module-tile-icon"><i class="fal fa-fw fa-building"></i></span>
                                                    <div class="module-tile-label">Clients</div>
                                                </div>
                                            </div>
                                        </router-link>
                                    </div>
            
                                    <div class="col-12 col-ss-6 col-sm-4 col-lg-3">
                                        <router-link class="module-tile" :to="{name: 'admin-tax-engine'}">
                                            <div class="module-tile-inner">
                                                <div class="module-tile-content">
                                                    <span class="module-tile-icon"><i class="fal fa-fw fa-money-bill"></i></span>
                                                    <div class="module-tile-label">Tax Engine</div>
                                                </div>
                                            </div>
                                        </router-link>
                                    </div>
            
                                    <div class="col-12 col-ss-6 col-sm-4 col-lg-3">
                                        <router-link class="module-tile" :to="{name: 'admin-pdf-forms'}">
                                            <div class="module-tile-inner">
                                                <div class="module-tile-content">
                                                    <span class="module-tile-icon"><i class="fal fa-fw fa-file-pdf"></i></span>
                                                    <div class="module-tile-label">PDF/YAML Forms</div>
                                                </div>
                                            </div>
                                        </router-link>
                                    </div>
            
                                    <div class="col-12 col-ss-6 col-sm-4 col-lg-3">
                                        <router-link class="module-tile" :to="{name: 'admin-payrolls'}">
                                            <div class="module-tile-inner">
                                                <div class="module-tile-content">
                                                    <span class="module-tile-icon"><i class="fal fa-fw fa-file-invoice-dollar"></i></span>
                                                    <div class="module-tile-label">Payrolls</div>
                                                </div>
                                            </div>
                                        </router-link>
                                    </div>

                                    <div class="col-12 col-ss-6 col-sm-4 col-lg-3">
                                        <router-link class="module-tile" :to="{name: 'admin-w2-review'}">
                                            <div class="module-tile-inner">
                                                <div class="module-tile-content">
                                                    <span class="module-tile-icon"><i class="fal fa-fw fa-folder"></i></span>
                                                    <div class="module-tile-label">W2 Review</div>
                                                </div>
                                            </div>
                                        </router-link>
                                    </div>

                                    <div class="col-12 col-ss-6 col-sm-4 col-lg-3">
                                        <a :href="admin_config_url" class="module-tile">
                                            <div class="module-tile-inner">
                                                <div class="module-tile-content">
                                                    <span class="module-tile-icon"><i class="fal fa-fw fa-cogs"></i></span>
                                                    <div class="module-tile-label">Admin Config</div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>

                                    <div class="col-12 col-ss-6 col-sm-4 col-lg-3">
                                        <a :href="legacy_admin_tools_url" class="module-tile">
                                            <div class="module-tile-inner">
                                                <div class="module-tile-content">
                                                    <span class="module-tile-icon"><i class="fal fa-fw fa-user-cog"></i></span>
                                                    <div class="module-tile-label">Legacy POS Admin Tools</div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>

                                    <div class="col-12 col-ss-6 col-sm-4 col-lg-3">
                                        <router-link class="module-tile" :to="{name: 'admin-utilities'}">
                                            <div class="module-tile-inner">
                                                <div class="module-tile-content">
                                                    <span class="module-tile-icon"><i class="fal fa-fw fa-tools"></i></span>
                                                    <div class="module-tile-label">Utilities</div>
                                                </div>
                                            </div>
                                        </router-link>
                                    </div>

                                    <div class="col-12 col-ss-6 col-sm-4 col-lg-3">
                                        <router-link class="module-tile" :to="{name: 'admin-ats-logs'}">
                                            <div class="module-tile-inner">
                                                <div class="module-tile-content">
                                                    <span class="module-tile-icon"><i class="fal fa-fw fa-person-sign"></i></span>
                                                    <div class="module-tile-label">ATS Logs</div>
                                                </div>
                                            </div>
                                        </router-link>
                                    </div>

                                    <div class="col-12 col-ss-6 col-sm-4 col-lg-3">
                                        <router-link class="module-tile" :to="{name: 'admin-geocoding'}">
                                            <div class="module-tile-inner">
                                                <div class="module-tile-content">
                                                    <span class="module-tile-icon"><i class="fal fa-fw fa-globe-americas"></i></span>
                                                    <div class="module-tile-label">Geocoding</div>
                                                </div>
                                            </div>
                                        </router-link>
                                    </div>

                                    <div class="col-12 col-ss-6 col-sm-4 col-lg-3">
                                        <router-link class="module-tile" :to="{name: 'admin-analytics'}">
                                            <div class="module-tile-inner">
                                                <div class="module-tile-content">
                                                    <span class="module-tile-icon"><i class="fal fa-fw fa-analytics"></i></span>
                                                    <div class="module-tile-label">Analytics</div>
                                                </div>
                                            </div>
                                        </router-link>
                                    </div>

                                    <div class="col-12 col-ss-6 col-sm-4 col-lg-3">
                                        <router-link class="module-tile" :to="{name: 'admin-debug'}">
                                            <div class="module-tile-inner">
                                                <div class="module-tile-content">
                                                    <span class="module-tile-icon"><i class="fal fa-fw fa-bug"></i></span>
                                                    <div class="module-tile-label">Test</div>
                                                </div>
                                            </div>
                                        </router-link>
                                    </div>

                                    <div class="col-12 col-ss-6 col-sm-4 col-lg-3">
                                        <router-link class="module-tile" :to="{name: 'admin-terminations-report'}">
                                            <div class="module-tile-inner">
                                                <div class="module-tile-content">
                                                    <span class="module-tile-icon"><i class="fal fa-fw fa-flag-checkered"></i></span>
                                                    <div class="module-tile-label">Terminations Report</div>
                                                </div>
                                            </div>
                                        </router-link>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        admin_config_url() {
            return this.$delta.POS_APP_URL + '/tools/'
        },
        legacy_admin_tools_url() {
            return this.$delta.POS_APP_URL + '/admin-tools/'
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Admin Tools')
    },
}
</script>
