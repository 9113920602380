<template>
    <div>
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">
                <div class="container-fluid">
                  
                    <div class="container-centered-s py-4">
      
                        <h5>{{ onboardingApplication.company.name }}</h5>
                        <h3>HR Information</h3>
        
                        <p class="mt-4">Please enter the following details so we can get you set up.</p>
        
                        <form @submit.prevent="onSubmit">
                            <form-errors :errors="formErrors"/>
        
                            <form-input label="First Name" v-model="formData.first_name" :errors="errors.first_name" type="text" maxlength="25" />
                            <form-input label="Middle Name" v-model="formData.middle_name" :errors="errors.middle_name" type="text" />
                            <form-input label="Last Name" v-model="formData.last_name" :errors="errors.last_name" type="text" maxlength="25" />
        
                            <div class="form-row">
                                <div class="col-8 col-sm-6">
                                    <form-input label="Suffix" v-model="formData.suffix" :errors="errors.suffix" type="select" :options="suffixOptions"/>
                                </div>
                            </div>
        
                            <div class="form-row">
                                <div class="col-8 col-sm-6">
                                    <form-input label="Social Security Number" v-model="formData.ssn" :errors="errors.ssn" type="ssn" v-if="!onboardingApplication.ssn_last_4 || editSSN" />
        
                                    <div class="mui-textfield form-control--lockable form-control--locked" v-if="onboardingApplication.ssn_last_4 && !editSSN">
                                        <label>Social Security Number</label>
                                        <div class="value-locked">
                                            XXX-XX-{{ onboardingApplication.ssn_last_4 }}
                                            <a href class="value-locked-button" @click.prevent="editSSN = true">Edit</a>
                                        </div>
                                        <input type="text" disabled />
                                    </div>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="col-8 col-sm-6">
                                    <form-input label="Date of Birth" v-model="formData.dob" :errors="errors.dob" type="date" placeholder="MM/DD/YYYY" />
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="col-8 col-sm-6">
                                    <form-input label="Marital Status" v-model="formData.marital_status" :errors="errors.marital_status" type="select" :options="maritalStatusOptions"/>
                                </div>
                            </div>

                            <div class="row mb-2 d-flex align-items-center">
                                <div class="col">
                                    <router-link v-if="previousPage" :to="previousPage">Back</router-link>
                                </div>

                                <div class="col text-right">
                                    <button class="btn btn-primary">Continue</button>
                                </div>
                            </div>
                        </form>

                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import FormInput from '@/components/FormInput'
import FormErrors from '@/components/FormErrors'
import FormMixin from '@/mixins/Form'
import api from '@/api'
import WizardMixin from './WizardMixin'

import states from '@/states'
import moment from 'moment'

const maritalStatusOptions = [
    {text: 'Single', value: 'Single'},
    {text: 'Married', value: 'Married'},
    {text: 'Civil Union', value: 'Civil Union'},
    {text: 'Registered Domestic Partner', value: 'Registered Domestic Partner'},
    {text: 'Separated', value: 'Separated'},
    {text: 'Legally Separated', value: 'Legally Separated'},
    {text: 'Divorced', value: 'Divorced'},
    {text: 'Widowed', value: 'Widowed'},
    {text: 'Prefer not to say', value: 'Prefer not to say'},
]

export default {
    mixins: [FormMixin, WizardMixin],
    components: {
        FormInput,
        FormErrors,
    },
    props: ['onboardingApplication'],
    computed: {
        suffixOptions() {return [
            {text: 'JR', value: 'JR'},
            {text: 'SR', value: 'SR'},
            {text: 'II', value: 'II'},
            {text: 'III', value: 'III'},
            {text: 'IV', value: 'IV'},
        ]},
        stateOptions() {
            const result = []
            states.forEach((s) => {
                result.push({text: s.name.toUpperCase(), value: s.abbreviation})
            })
            return result
        },
    },
    data() {
        return {
            editSSN: false,
            maritalStatusOptions: maritalStatusOptions,
            formRules: {
                first_name: {presence: {allowEmpty: false}, },
                last_name: {presence: {allowEmpty: false}, },
                dob: {presence: {allowEmpty: false}, date: {latest: moment().format()}},
                ssn: {presence: {allowEmpty: true}},
                marital_status: {presence: {allowEmpty: true}, },
            },
            formLabels: {
                ssn: 'SSN',
                dob: 'Date of Birth',
                marital_status: 'Marital Status',
            },
            formData: this.makeFormData(),
        }
    },
    methods: {
        makeFormData() {
            return {
                action: 'give-hr-info',

                first_name: this.onboardingApplication.first_name,
                middle_name: this.onboardingApplication.middle_name,
                last_name: this.onboardingApplication.last_name,
                suffix: this.onboardingApplication.suffix,
                ssn: '',
                dob: this.onboardingApplication.dob,
                marital_status: this.onboardingApplication.marital_status,
            }
        },
        getFormRule(field) {
            if (field == 'ssn') {
                if (!this.onboardingApplication.ssn_last_4 || this.editSSN) {
                    return {presence: {allowEmpty: false}, ssn: true}
                }
                else {
                    return {presence: {allowEmpty: true}}
                }
            }
            else {
                return FormMixin.methods.getFormRule.call(this, field)
            }
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }
            this.$store.dispatch('START_LOADING')
            api.patch(`/me/onboarding/${this.onboardingApplication.id}/onboarding-application`, this.getFormData()).then((resp) => {
                this.$emit('updated', resp)
                this.$emit('next')
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })
        },
        reset: function() {
            this.errors = {}
            this.formErrors = []
            this.formData = this.makeFormData()
        },
    },
}
</script>
