<template>
    <modal @close="$emit('close')" ref="modal" size="lg" :autoshow="true">
        <template v-slot:title>{{ mode == 'add' ? 'Add' : 'Edit' }} Direct Deposit Information {{ step == 'summary' ? " - Summary" : "" }}</template>

        <slot>
            <form-errors :errors="errors['__all__']" />

            <template v-if="step == 'num-accounts'">
                <div class="form-row">
                    <div class="col-12 d-flex flex-row">
                        <p>How many bank accounts will each paycheck be directly deposited into?</p>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col">
                        <form-input type="radio" v-model="formData.num_accounts" :errors="errors.num_accounts" :options="numAccountsOptions" />
                    </div>
                </div>
                <div v-if="showTurnOffDirectDepositLink" class="form-row mb-4">
                    <div class="col text-center">
                        <a href @click.prevent="formData.num_accounts = 0; nextStep()">
                            <template v-if="!mandatoryDirectDeposit">Turn off Direct Deposit (receive a paper check instead)</template>
                            <template v-else-if="mandatoryDirectDeposit && !approvalView">Remove Direct Deposit account (receive a paycard instead)</template>
                            <template v-else-if="mandatoryDirectDeposit && approvalView && canOverrideMandatoryDirectDeposit">Remove Direct Deposit account</template>
                        </a>
                    </div>
                </div>
            </template>

            <template v-if="step == 'two-accounts-to-one'">
                <div class="form-row">
                    <div class="col">
                        <p>What would you like to do?</p>
                        <div class="form-check-set mb-2">
                            <ul class="list-unstyled pl-2">
                                <li v-for="(account, idx) in sortedDirectDepositAccounts" :key="idx" class="option form-check pl-0">
                                    <label>
                                        <input :id="idx" :name="'account-to-keep'" type="radio" :value="idx" @click="formData.account_to_keep_idx = idx" :checked="formData.account_to_keep_idx == idx" />
                                        <span class="ml-1"><strong>Keep</strong> this account and deposit 100% of each paycheck into it.</span>
                                    </label>
                                    <direct-deposit-account-card
                                        :account="account"
                                        :numAccounts="sortedDirectDepositAccounts.length"
                                        :approvalView="approvalView"
                                        :url="url"
                                    >
                                    </direct-deposit-account-card>
                                </li>
                                <li class="option form-check pl-0">
                                    <label>
                                        <input class="mt-1 mb-4" :name="'account-to-keep'" type="radio" :value="-1" @click="formData.account_to_keep_idx = -1" :checked="formData.account_to_keep_idx == -1" />
                                        <span class="ml-1"><strong>Remove</strong> both accounts and enter a new account's details.</span>
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </template>

            <div v-if="step == 'account-details'">
                <div v-for="(acct, idx) in [formData.accounts.portion_account, formData.accounts.remainder_account].filter(a => !!a)" :key="idx">
                    <p v-if="formData.num_accounts == 1 && acct.split_type == 'remainder'" class="mt-1 mb-1">
                        <strong>100% of each paycheck</strong> will be deposited into this account:
                    </p>
                    <p v-if="formData.num_accounts > 1 && acct.split_type == 'remainder'" class="mt-2 mb-1">
                        The <strong>remainder</strong> of each paycheck will be deposited into this account:
                    </p>
                    <p v-if="formData.num_accounts > 1 && acct.split_type == 'percentage' || acct.split_type == 'fixed'" class="mt-1 mb-1">
                        A <strong>portion</strong> of each paycheck will be deposited into this account:
                    </p>
                    <div class="dd-account-details-form card mb-1">
                        <div class="card-body px-2 py-2">
                            <div class="form-row">
                                <div class="col">
                                    <form-errors :errors="errors[`accounts.${getAccountKey(acct)}.__all__`]"/>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-md-6 col-12 d-flex flex-column">
                                    <!-- In the one-account-to-two accounts scenario, show a select to fill from existing account or enter new account -->
                                    <div v-if="oneAccountToTwoCase">
                                        <form-input v-model="acct.copy_from_id" @update:model-value="onExistingAccountSelect($event, getAccountKey(acct))" :errors="errors[`accounts.${getAccountKey(acct)}.id`]" type="select" noblank="true" label="Account" :options="existingAccountOptions"/>
                                    </div>
                                    <!-- In other # account scenarios, show a readonly bank name for existing accounts -->
                                    <div v-else-if="acct.id && acct.bank_name" class="form-row">
                                        <div class="col">
                                            <p>
                                                <span class="truncate-wrapper truncate-90 truncate-with-last4">
                                                    <span class="truncate-content" :title="acct.bank_name || 'Bank'">
                                                        <strong>{{ acct.bank_name || 'Bank' }}</strong>
                                                    </span>
                                                    <span class="truncate-suffix"><strong>({{ acct.account_number_redacted.slice(acct.account_number_redacted.length - 4) }})</strong></span>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <!-- In all # account scenarios, show readonly fields if existing account or inputs if new account -->
                                    <div class="form-row">
                                        <div v-if="acct.id" class="col">
                                            <div class="mui-textfield">
                                                <span class="textfield">{{ acct.routing_number }}</span>
                                                <label>Routing Number</label>
                                            </div>
                                        </div>
                                        <div v-else class="col">
                                            <form-input
                                                v-model="acct.routing_number"
                                                :errors="errors[`accounts.${getAccountKey(acct)}.routing_number`]"
                                                type="integer-string"
                                                label="Routing Number"
                                                maxlength="9"
                                                extraclasses=""
                                                @update:model-value="onUpdateRoutingNumber(acct)"
                                                :helperHtml="bankNameHelperHtml(acct.bank_name)"
                                                :popoverHelperHtml="'<p>Your bank&rsquo;s Routing Number is the first set of numbers on the bottom of your check. It is 9 digits long.</p>'"
                                            />
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div v-if="acct.id" class="col">
                                            <div class="mui-textfield">
                                                <span class="textfield">{{ acct.account_number_redacted }}</span>
                                                <label>Account Number</label>
                                            </div>
                                        </div>
                                        <div v-else class="col">
                                            <form-input
                                                v-model="acct.account_number"
                                                :errors="errors[`accounts.${getAccountKey(acct)}.account_number`]"
                                                type="text"
                                                label="Account Number"
                                                maxlength="17"
                                                :popoverHelperHtml="'<p>Your Account Number is the second set of numbers on the bottom of your check. It can vary in length.</p>'"
                                            />
                                            <form-input
                                                v-model="acct.confirm_account_number"
                                                :errors="errors[`accounts.${getAccountKey(acct)}.confirm_account_number`]"
                                                type="text"
                                                label="Confirm Account Number"
                                                maxlength="17"
                                            />
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="col">
                                            <div v-if="acct.id" class="mui-textfield">
                                                <span class="textfield">{{ getDisplayAccountType(acct.account_type) }}</span>
                                                <label>Account Type</label>
                                            </div>
                                            <form-input v-else v-model="acct.account_type" :errors="errors[`accounts.${getAccountKey(acct)}.account_type`]" type="select" label="Account Type" :options="accountTypeOptions"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-12 flex-row">
                                    <div class="form-row">
                                        <div class="col">
                                            <div class="label-like">
                                                How much of each paycheck?
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="acct.split_type == 'remainder'" class="form-row">
                                        <div class="col">
                                            <div class="mui-textfield pt-0">
                                                <span class="textfield">Remainder</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else class="form-row">
                                        <div class="col-4">
                                            <form-input v-if="acct.split_type == 'fixed'" v-model="acct.amount" :errors="errors[`accounts.${getAccountKey(acct)}.amount`]" type="currency" prefix="$" />
                                            <form-input v-if="acct.split_type == 'percentage'" v-model="acct.percentage" :errors="errors[`accounts.${getAccountKey(acct)}.percentage`]" type="integer" maxlength="2" prefix="%" />
                                        </div>
                                        <div class="col-8">
                                            <form-input v-model="acct.split_type" :errors="errors.split_type" noblank="true" class="dd-split-type-select" type="select" :options="splitTypeOptions" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row" v-if="acct.id && !oneAccountToTwoCase">
                                <div class="col">
                                    <a href @click.prevent="clearAccount(acct)">
                                        Use a Different Account
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-2 mb-4 d-flex justify-content-center" v-if="formData.num_accounts == 2">
                    <button class="btn btn-outline-primary px-8" type="button" @click.prevent="swapAccounts()">
                        <i class="far fa-fw fa-random mr-1"></i>Swap These Accounts
                    </button>
                </div>
            </div>

            <template v-if="step == 'summary'">
                <div v-if="formData.num_accounts == 0">
                    <p v-if="this.directDepositAccounts && this.directDepositAccounts.length > 0">
                        Your bank account{{ this.directDepositAccounts.length > 1 ? 's' : '' }} will be removed
                        <template v-if="!mandatoryDirectDeposit">and you will receive <strong>100% of each paycheck in a paper check</strong></template>
                        <template v-else-if="mandatoryDirectDeposit && !approvalView">and you will receive <strong>100% of each paycheck on a paycard</strong></template>
                    </p>
                </div>
                <template v-else>
                <div v-for="(account, idx) in [formData.accounts.portion_account, formData.accounts.remainder_account].filter(a => !!a)" :key="idx">
                    <p v-if="formData.num_accounts == 1 && account.split_type == 'remainder'" class="mt-1 mb-1">
                        <strong>100% of each paycheck</strong> will be deposited into this account:
                    </p>
                    <p v-if="formData.num_accounts > 1 && account.split_type == 'remainder'" class="mt-1 mb-1">
                        The <strong>remainder</strong> of each paycheck will be deposited into this account:
                    </p>
                    <p v-if="formData.num_accounts > 1 && account.split_type == 'percentage' || account.split_type == 'fixed'" class="mt-1 mb-1">
                        A <strong>portion</strong> of each paycheck will be deposited into this account:
                    </p>
                    <direct-deposit-account-card
                        :account="account"
                        :numAccounts="formData.accounts.length"
                        :approvalView="approvalView"
                        :url="url"
                    >
                    </direct-deposit-account-card>
                </div>
                </template>
            </template>
        </slot>

        <template v-slot:footer>
            <div class="form-row">
                <div class="col">
                    <button v-if="step == 'num-accounts'" type="button" class="btn btn-block btn-outline-primary" @click.prevent="close()">Cancel</button>
                    <button v-else type="button" class="btn btn-block btn-outline-primary" @click.prevent="prevStep()">Back</button>
                </div>
                <div class="col">
                    <button v-if="step == 'summary'" type="submit" class="btn btn-block btn-primary" @click.prevent="onSubmit()">Save</button>
                    <button v-else class="btn btn-block btn-primary" @click.prevent="nextStep()">Next</button>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import api from '@/api'
import DirectDepositAccountCard from '@/components/DirectDepositAccountCard'
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'

export default {
    mixins: [FormMixin, ModalMixin],
    components: {DirectDepositAccountCard, },
    props: ['directDepositAccounts', 'url', 'mode', 'approvalView', 'essView', 'mandatoryDirectDeposit', 'canOverrideMandatoryDirectDeposit'],
    emits: ['close', 'updated'],
    watch: {
        'formData.accounts.portion_account.split_type'() {
            if (this.formData.accounts.portion_account) {
                if (this.formData.accounts.portion_account['split_type'] == 'percentage') {
                    this.formData.accounts.portion_account['amount'] = ''
                    if (this.errors['accounts.portion_account.percentage']) {delete this.errors['accounts.portion_account.percentage']}
                } else if (this.formData.accounts.portion_account['split_type'] == 'fixed') {
                    this.formData.accounts.portion_account['percentage'] = ''
                    if (this.errors['accounts.portion_account.percentage']) {delete this.errors['accounts.portion_account.percentage']}
                }
            }
        },
    },
    computed: {
        accountTypeOptions() {
            return [
                {text: 'Checking', value: 'CHECKING'},
                {text: 'Savings', value: 'SAVINGS'},
            ]
        },
        existingAccountOptions() {
            let options = [
                {text: 'NEW ACCOUNT', value: ''}
            ]
            this.directDepositAccounts.forEach((a) => {
                options.push({
                    text: this.truncateBankName(a.bank_name, a.account_number_last_4),
                    value: a.id
                })
            })
            return options
        },
        splitTypeOptions() {
            return [
                {text: 'Dollars ($)', value: 'fixed'},
                {text: 'Percent (%)', value: 'percentage'},
            ]
        },
        numAccountsOptions() {
            return [
                {
                    html: `<strong>Single Account</strong>${this.directDepositAccounts.length == 1 ? ' (Current Setting)' : ''}` ,
                    value: 1,
                    helper_text: 'Deposit the entire amount of each paycheck into one bank account'
                },
                {
                    html: `<strong>Split Between Two Accounts</strong>${this.directDepositAccounts.length == 2 ? ' (Current Setting)' : ''}`,
                    value: 2,
                    helper_text: 'Deposit a portion of each paycheck into one bank account, and deposit the remainder of each paycheck into a second bank account'
                },
            ]
        },
        sortedDirectDepositAccounts() {
            const accounts = this.directDepositAccounts.slice()

            return accounts.sort((a, b) => {
                if (a.split_type == 'remainder') {
                    return 1
                }
                if (b.split_type == 'remainder') {
                    return -1
                }
            })
        },
        oneAccountToTwoCase() {
            return (this.directDepositAccounts && this.directDepositAccounts.length == 1 && this.formData && this.formData.num_accounts == 2)
        },
        twoAccountsToOneCase() {
            return (this.directDepositAccounts && this.directDepositAccounts.length == 2 && this.formData && this.formData.num_accounts == 1)
        },
        showTurnOffDirectDepositLink() {
            if (this.mode != 'edit') {
                return false
            }
            if (this.essView && this.mandatoryDirectDeposit) {
                return false
            }
            if (this.approvalView && this.mandatoryDirectDeposit && !this.canOverrideMandatoryDirectDeposit) {
                return false
            }
            return true
        }
    },
    data() {
        let baseRules = {
            num_accounts: {presence: {allowEmpty: false}, },
        }

        let remainderAccountRules = {
            'accounts.remainder_account.routing_number': {presence: {allowEmpty: false}, aba_routing_number: true},
            'accounts.remainder_account.account_number': {presence: {allowEmpty: false}, },
            'accounts.remainder_account.confirm_account_number': {presence: {allowEmpty: false}, },
            'accounts.remainder_account.account_type': {presence: {allowEmpty: false}, },
        }

        let portionAccountRules = {
            'accounts.portion_account.routing_number': {presence: {allowEmpty: false}, aba_routing_number: true},
            'accounts.portion_account.account_number': {presence: {allowEmpty: false}, },
            'accounts.portion_account.confirm_account_number': {presence: {allowEmpty: false}, },
            'accounts.portion_account.account_type': {presence: {allowEmpty: false}, },
            'accounts.portion_account.split_type': {presence: {allowEmpty: false}, },
            'accounts.portion_account.percentage': {presence: {allowEmpty: false}, numericality: {greaterThan: 0}},
            'accounts.portion_account.amount': {presence: {allowEmpty: false}, numericality: {greaterThan: 0}},
        }

        let formLabels = {
            num_accounts: 'Number of accounts',
            'accounts.remainder_account.routing_number': 'Routing Number',
            'accounts.remainder_account.account_number': 'Account Number',
            'accounts.remainder_account.confirm_account_number': 'Account Number',
            'accounts.remainder_account.account_type': 'Account Type',
            'accounts.portion_account.routing_number': 'Routing Number',
            'accounts.portion_account.account_number': 'Account Number',
            'accounts.portion_account.confirm_account_number': 'Account Number',
            'accounts.portion_account.account_type': 'Account Type',
            'accounts.portion_account.split_type': 'Split Type',
            'accounts.portion_account.percentage': 'Percentage',
            'accounts.portion_account.amount': 'Amount',
        }

        return {
            formRules: baseRules,
            formLabels: formLabels,
            baseRules: baseRules,
            remainderAccountRules: remainderAccountRules,
            portionAccountRules: portionAccountRules,
            formData: this.makeFormData(),
            bank_name: '',
            step: 'num-accounts',
            errors: {}
        }
    },
    methods: {
        makeFormData() {
            let formData = {
                num_accounts: this.directDepositAccounts ? this .directDepositAccounts.length : null,
                accounts: {
                    remainder_account: null,
                    portion_account: null,
                }
            }
            return formData
        },
        makeAccountsFormData(existingAccounts, forceRemainder) {
            // take any existing accounts as array (whether it's from directDepositAccounts or formData.accounts)
            // and return form data in the correct format

            let accountsFormData = {
                remainder_account: null,
                portion_account: null,
            }

            if (existingAccounts) {
                existingAccounts = JSON.parse(JSON.stringify(existingAccounts))
                if (existingAccounts.length == 1 && forceRemainder) {
                    // make the passed account a remainder account, regardless of whether it is currently
                    existingAccounts[0].split_type = 'remainder'
                    existingAccounts[0].percentage = ''
                    existingAccounts[0].amount = ''
                }
                for (const account of existingAccounts) {
                    if (account.split_type == 'remainder') {
                        accountsFormData.remainder_account = this.makeAccountFormData(account)
                    } else if (this.formData.num_accounts > 1) {
                        accountsFormData.portion_account = this.makeAccountFormData(account)
                    }
                }
            }
            if (!accountsFormData.remainder_account) {
                accountsFormData.remainder_account = this.makeAccountFormData()
                accountsFormData.remainder_account.split_type = 'remainder'
            }
            if (this.formData.num_accounts > 1 && !accountsFormData.portion_account) {
                accountsFormData.portion_account = this.makeAccountFormData()
                accountsFormData.portion_account.split_type = 'percentage'
            }
            return accountsFormData
        },
        makeAccountFormData(existingAccount, includeSplit=true) {
            if (existingAccount) {
                let ret = {
                    routing_number: existingAccount.routing_number,
                    account_number: existingAccount.account_number || '',
                    confirm_account_number: existingAccount.account_number || '',
                    account_number_redacted: existingAccount.account_number_redacted || '',
                    account_type: existingAccount.account_type || '',
                    bank_name: existingAccount.bank_name,
                    id: existingAccount.id || '',
                    copy_from_id: existingAccount.id || '',
                }
                if (includeSplit) {
                    ret = {
                        ...ret,
                        split_type: existingAccount.split_type,
                        amount: existingAccount.amount,
                        percentage: existingAccount.percentage,
                    }
                }
                return ret
            } else {
                let ret = {
                    routing_number: '',
                    account_number: '',
                    confirm_account_number: '',
                    account_number_redacted: '',
                    account_type: '',
                    bank_name: '',
                    id: '',
                    copy_from_id: '',
                }
                if (includeSplit) {
                    ret = {
                        ...ret,
                        split_type: '',
                        amount: '',
                        percentage: '',
                    }
                }
                return ret
            }
        },
        getAccountsFormDataAsArray() {
            return [this.formData.accounts.remainder_account, this.formData.accounts.portion_account].slice(0, this.formData.num_accounts)
        },
        getSubmitFormData() {
            let submitFormData = {
                accounts: []
            }
            for (let a of this.getAccountsFormDataAsArray()) {
                submitFormData.accounts.push({
                    account_number: a.account_number,
                    routing_number: a.routing_number,
                    account_type: a.account_type,
                    split_type: a.split_type,
                    amount: a.amount,
                    percentage: a.percentage,
                    bank_name: a.bank_name,
                    id: a.id,
                })
            }
            return submitFormData
        },
        setErrors(errors) {
            this.errors['__all__'] = errors.__all__
            if (errors.accounts && errors.accounts.length) {
                for (const [idx, account] of errors.accounts.entries()) {
                    if (idx == 0) {
                        for (const [errorKey, errorMessages] of Object.entries(account)) {
                            this.errors[`accounts.remainder_account.${errorKey}`] = errorMessages
                        }
                    } else if (idx == 1) {
                        for (const [errorKey, errorMessages] of Object.entries(account)) {
                            this.errors[`accounts.portion_account.${errorKey}`] = errorMessages
                        }
                    }
                }
            }
        },
        reset() {
            this.formData = this.makeFormData()
            this.resetErrors()
        },
        resetErrors() {
            this.errors =  {}
            this.formErrors = []
        },
        nextStep() {
            if (this.step == 'num-accounts') {
                if (!this.validate(['num_accounts'])) {
                    return
                }
                if (this.formData.num_accounts == 0) {
                    this.step = 'summary' // skip account-details when turning off direct deposit
                } else if (this.twoAccountsToOneCase) {
                    // Special case step to prompt user which account(s) to remove before continuing to edit account details
                    this.step = 'two-accounts-to-one'
                } else {
                    // Depending on whether we have existing form data (from having clicked Back and Next again),
                    // or existing direct deposit accounts, or we're starting from scratch,
                    // create the appropriate form data for the accounts as we go to the 'account-details' step
                    let existingAccounts
                    if (this.formData.accounts.remainder_account && this.formData.accounts.portion_account) {
                        existingAccounts = [this.formData.accounts.remainder_account, this.formData.accounts.portion_account]
                    } else if (this.formData.accounts.remainder_account) {
                        existingAccounts = [this.formData.accounts.remainder_account]
                    } else if (this.directDepositAccounts.length) {
                        existingAccounts = this.directDepositAccounts
                    }
                    this.formData.accounts = this.makeAccountsFormData(existingAccounts)
                    this.step = 'account-details'
                }
            } else if (this.step == 'two-accounts-to-one') {
                let existingAccounts
                if (this.formData.account_to_keep_idx == -1) {
                    existingAccounts = []
                } else {
                    existingAccounts = [this.sortedDirectDepositAccounts[this.formData.account_to_keep_idx]]
                }
                this.formData.accounts = this.makeAccountsFormData(existingAccounts, true)
                // if we're keeping neither existing account and entering new data, go to account details
                // else if we're keeping an existing account, go straight to summary
                if (this.formData.account_to_keep_idx == -1) {
                    this.step = 'account-details'
                } else {
                    this.step = 'summary'
                }
            } else if (this.step == 'account-details') {
                if (!this.validateAccountDetails()) {
                     return
                }
                this.step = 'summary'
            }
        },
        prevStep() {
            if (this.step == 'account-details') {
                if (this.twoAccountsToOneCase) {
                    this.step = 'two-accounts-to-one'
                } else {
                    this.step = 'num-accounts'
                }
            } else if (this.step == 'two-accounts-to-one') {
                this.step = 'num-accounts'
            } else if (this.step == 'summary') {
                if (this.formData.num_accounts == 0) {
                    this.step = 'num-accounts'
                } else if (this.twoAccountsToOneCase && this.formData.account_to_keep != -1) {
                    this.step = 'two-accounts-to-one'
                } else {
                    this.step = 'account-details'
                }
            }
        },
        getAccountKey(account) {
            if (account['split_type'] == 'remainder') {
                return 'remainder_account'
            } else {
                return 'portion_account'
            }
        },
        onUpdateRoutingNumber(account) {
            if (!account['routing_number']) {
                account.bank_name = ''
                return
            }

            // only ask the API to validate if it looks like a valid-looking number
            if (account['routing_number'].length != 9) {
                account.bank_name = ''
                return
            }

            api.post('/validate/brn', {'routing_number': account['routing_number']}).then((resp) => {
                delete this.errors[`accounts.${this.getAccountKey(account)}.routing_number`]
                account.bank_name = resp['bank_name']
            }).catch((errors) => {
                this.errors[`accounts.${this.getAccountKey(account)}.routing_number`] = errors['routing_number']
                account.bank_name = ''
            })
        },
        swapAccounts() {
            let remainderAccountCopy = { ...this.formData.accounts.remainder_account }
            let portionAccountCopy = { ...this.formData.accounts.portion_account }
            // don't swap split_type, amount, and percentage
            for (let a of [remainderAccountCopy, portionAccountCopy]) {
                delete a['split_type']
                delete a['amount']
                delete a['percentage']
            }
            this.resetErrors()
            this.formData.accounts = {
                'remainder_account': Object.assign(this.formData.accounts.remainder_account, portionAccountCopy),
                'portion_account': Object.assign(this.formData.accounts.portion_account, remainderAccountCopy),
            }
        },
        clearAccount(account) {
            if (account.split_type == 'remainder') {
                let blankAccountFormData = this.makeAccountFormData()
                blankAccountFormData.split_type = 'remainder'
                this.formData.accounts.remainder_account = blankAccountFormData
            } else {
                let blankAccountFormData = this.makeAccountFormData()
                blankAccountFormData.split_type = account.split_type
                this.formData.accounts.portion_account = blankAccountFormData
            }
        },
        onExistingAccountSelect(newId, accountKey) {
            if (accountKey == 'portion_account') {
                // if the remainder_account is selected from the portion_account dropdown, swap the two accounts
                if (newId && newId == this.formData.accounts.remainder_account.id) {
                    this.swapAccounts()
                    // reset copy_from_id to id after swap
                    this.formData.accounts.portion_account.copy_from_id = this.formData.accounts.portion_account.id
                    this.formData.accounts.remainder_account.copy_from_id = this.formData.accounts.remainder_account.id
                } else { // else, just fill from existing account without swapping
                    let existingAccount = this.directDepositAccounts.find(a => a.id == newId)
                    this.formData.accounts.portion_account = Object.assign(this.formData.accounts.portion_account, this.makeAccountFormData(existingAccount, false))
                }
            } else if (accountKey == 'remainder_account') {
                // if the portion_account is selected from the remainder_account dropdown, swap the two accounts
                if (newId && this.formData.accounts.portion_account && newId == this.formData.accounts.portion_account.id) {
                    this.swapAccounts()
                    // reset copy_from_id to id after swap
                    this.formData.accounts.portion_account.copy_from_id = this.formData.accounts.portion_account.id
                    this.formData.accounts.remainder_account.copy_from_id = this.formData.accounts.remainder_account.id
                } else { // else, just fill from existing account without swapping
                    let existingAccount = this.directDepositAccounts.find(a => a.id == newId)
                    this.formData.accounts.remainder_account = Object.assign(this.formData.accounts.remainder_account, this.makeAccountFormData(existingAccount, false))
                }
            }
        },
        validateAccountDetails() {
            // early-return if we know from the results of onUpdateRoutingNumber that the routing number(s) are not valid
            // NB: this doesn't validate the routing number for an existing account (but an error *will* eventually be returned on the final form submit if that is invalid)
            if (this.errors['accounts.remainder_account.routing_number'] && !this.formData.accounts.remainder_account.id) {
                return false
            }
            if (this.formData.num_accounts == 2 && this.errors['accounts.portion_account.routing_number'] && !this.formData.accounts.portion_account.id) {
                return false
            }

            // do the validation via FormMixin
            let formRules = {
                ...JSON.parse(JSON.stringify(this.baseRules)),
                ...JSON.parse(JSON.stringify(this.remainderAccountRules)),
            }

            // remove validation on account_number if we're using an existing account (and therefore account_number is redacted)
            formRules['accounts.remainder_account.account_number']['presence']['allowEmpty'] = this.formData.accounts.remainder_account['id'] ? true : false
            formRules['accounts.remainder_account.confirm_account_number']['presence']['allowEmpty'] = this.formData.accounts.remainder_account['id'] ? true : false

            // Make sure account numbers match
            if (this.formData.accounts.remainder_account['account_number'] != this.formData.accounts.remainder_account['confirm_account_number'] && !this.formData.accounts.remainder_account['id']) {
                this.errors['accounts.remainder_account.confirm_account_number'] = ['Account Number must match']
                return false
            }

            // Include validation on portion_account if we're submitting 2 accounts
            if (this.formData.num_accounts == 2) {
                Object.assign(formRules, JSON.parse(JSON.stringify(this.portionAccountRules)))
                // remove validation on account_number if we're using an existing account (and therefore account_number is redacted)
                formRules['accounts.portion_account.account_number']['presence']['allowEmpty'] = this.formData.accounts.portion_account['id'] ? true : false
                formRules['accounts.portion_account.confirm_account_number']['presence']['allowEmpty'] = this.formData.accounts.portion_account['id'] ? true : false
                // remove validation for the unselected split_type
                if (this.formData.accounts.portion_account['split_type'] == 'percentage') {
                    delete formRules['accounts.portion_account.amount']
                }
                if (this.formData.accounts.portion_account['split_type'] == 'fixed') {
                    delete formRules['accounts.portion_account.percentage']
                }
                // Make sure account numbers for portion account match
                if (this.formData.accounts.portion_account['account_number'] != this.formData.accounts.portion_account['confirm_account_number'] && !this.formData.accounts.portion_account['id']) {
                    this.errors['accounts.portion_account.confirm_account_number'] = ['Account Number must match']
                    return false
                }
            }

            this.formRules = formRules

            return this.validate()
        },
        onSubmit() {
            this.$store.dispatch('START_LOADING')
            let data = this.getSubmitFormData()
            if (data.accounts.length == 0) {
                api.del(this.url, data).then((resp) => {
                    this.$store.dispatch('STOP_LOADING')
                    this.$emit('updated', resp)
                    this.close()
                }).catch((errors) => {
                    this.setErrors(errors)
                    this.$store.dispatch('STOP_LOADING')
                })
            } else {
                api.post(this.url, data).then((resp) => {
                    this.$store.dispatch('STOP_LOADING')
                    this.$emit('updated', resp)
                    this.close()
                }).catch((errors) => {
                    this.setErrors(errors)
                    // Go back to the account details step to show the errors in context
                    this.step = 'account-details'
                    this.$store.dispatch('STOP_LOADING')
                })
            }
        },
        bankNameHelperHtml(bankName) {
            if (!bankName) {
                return ''
            }

            return '<i class="fas fa-fw fa-check mr-hf text-primary" aria-hidden="true"></i> ' + bankName
        },
        truncateBankName(bankName, accountLast4) {
            return `${bankName.slice(0, 20)}${bankName.length > 20 ? '...' : ''} (${accountLast4})`
        },
        getDisplayAccountType(accountType) {
            if (accountType == 'CHECKING' || accountType == 'checking') {
                return 'Checking'
            } else if (accountType == 'SAVINGS' || accountType == 'savings') {
                return 'Savings'
            } else {
                return accountType
            }
        }
    },
}
</script>
